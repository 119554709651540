import React from 'react'
import { Flex, Button, Box, Heading } from '@chakra-ui/core'
import { Link } from 'react-router-dom'

import jupiter from '../assets/images/jupiter.png'

function Feel() {
  return (
    <Box bgImage={`url(${jupiter})`}>
      <Flex padding="5rem" align="center" justify="space-between" maxW="1280px" mx="auto" px="16">
        <Heading color="#fff" fontFamily="Avenir Black" fontSize={['2rem', '3rem', '4.2rem']}>
          Get a feel for Warner prints
        </Heading>
        <Link to="/contact-us">
          <Button
            fontSize={['1.2rem', '1.4rem', '1.8rem']}
            py={['2rem', '2.4rem', '2.7rem']}
            px={['1.5rem', '2.5rem', '5rem']}
            borderRadius="0"
            fontWeight="normal"
            color="#FD3B90"
            border="none"
            bg="white"
          >
            Contact us
          </Button>
        </Link>
      </Flex>
    </Box>
  )
}

export { Feel }
