/* eslint-disable react/no-children-prop */
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Box, Flex, Text, Image, Link as CLink } from '@chakra-ui/core'
import Pagination from 'react-js-pagination'

import { PageWrapper } from '../components/layout'
import { Feel } from '../components/Feel'
import { Product } from '../components/common'
import { ProductsSkeleton } from '../components/skeletons'

import { getTag } from '../network/product'

import seller from '../assets/images/seller.png'

function Tag() {
  const [currentPage, setCurrentPage] = useState(1)
  const { type } = useParams()

  const { isLoading, data, isError } = useQuery(['tag', type], getTag)

  const handlePageChange = (page) => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }

  if (isLoading) {
    return <ProductsSkeleton />
  }

  if (isError) {
    return <p>Error Occured</p>
  }

  return (
    <>
      <Box bg="#eef5fd">
        <Box maxW="1280px" mx="auto" pl={4}>
          <Flex
            p="1rem"
            ml="2rem"
            justify="flex-start"
            alignItems="center"
            h="42px"
            fontSize="1.2rem"
          >
            <Box
              background="#E3EAF1"
              border="1px solid #EDF5FD"
              pl="8px"
              pr="10px"
              py="4.7px"
              mr="2rem"
              style={{ clipPath: 'polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)' }}
            >
              <Text fontFamily="light">
                <CLink as={Link} color="#808080" to="/">
                  Home
                </CLink>
              </Text>
            </Box>
            <Text color="#808080" mr="2.5rem" fontFamily="light">
              {data.name}
            </Text>
          </Flex>
        </Box>
        <Box backgroundColor="#177BE2">
          <Box maxW="1280px" mx="auto" pl={4}>
            <Text color="white" fontSize="28px" fontFamily="black" p="30px">
              {data.name}
            </Text>
          </Box>
        </Box>
      </Box>

      <PageWrapper mt="6rem">
        <Flex justify="space-between">
          <Image
            d={['none', 'none', 'none', 'flex']}
            mr="1.7rem"
            w="220px"
            flexShrink="0"
            src={seller}
            maxH="450px"
          />
          <Box w="100%" display="flex" flexDir="column">
            <Flex wrap="wrap" mt="2.5rem">
              {data.products.length > 12
                ? data.products
                    .slice((currentPage - 1) * 12, (currentPage - 1) * 12 + 12)
                    .map((item) => <Product key={item.id} item={item} details detailsLen={100} />)
                : data.products.map((item) => (
                    <Product key={item.id} item={item} details detailsLen={100} />
                  ))}
            </Flex>
          </Box>
        </Flex>
        <Pagination
          style={{ display: 'flex' }}
          activePage={currentPage}
          itemsCountPerPage={12}
          totalItemsCount={data.products.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          nextPageText="Next"
          prevPageText="Prev"
          itemClassFirst="end"
          itemClassLast="end"
          itemClassPrev="step"
          itemClassNext="step"
        />
      </PageWrapper>
      <Feel />
    </>
  )
}

export { Tag }
