import React, { useContext } from 'react'
import commafy from 'commafy'
import { Box, Flex, Text, Heading, Icon } from '@chakra-ui/core'

import { CartContext } from '../../context/cart/provider'
import { DUPLICATE_ITEM, REMOVE_FROM_CART } from '../../context/cart'

function CartItem({ item }) {
  const { dispatch } = useContext(CartContext)

  return (
    <Box
      position="relative"
      p="3.2rem"
      pr="7rem"
      mt="5rem"
      border="1px solid #F4F4F4"
      boxShadow="0px 3px 6px #00000010"
    >
      <Flex mb="3.8rem">
        <Heading
          w="full"
          d="flex"
          justifyContent="space-between"
          fontFamily="heavy"
          fontSize="2.2rem"
        >
          {item.name}
          <Text fontFamily="heavy" as="span">
            ₦{commafy(item.price.toFixed(2))}
          </Text>
        </Heading>
      </Flex>
      <Box maxW="412px">
        {item.choices !== null &&
          Object.keys(item.choices).map((opt) => (
            <Type
              key={opt}
              name={opt.split('.')[1].replace(/-/g, ' ')}
              type={item.choices[opt].name}
            />
          ))}
        {/* <Text mt="1.4rem" color="brand.pink" fontFamily="heavy">
          Edit
        </Text> */}
      </Box>
      <Flex mt="3.1rem" justify="space-between">
        {/* <Text fontFamily="medium" fontSize="1.4rem">
          Do you have a voucher code? <span style={{ color: '#FD3B90' }}>Apply here</span>
        </Text> */}
        <Text
          d={['none', 'none', 'flex']}
          alignItems="center"
          ml="2"
          color="brand.pink"
          fontFamily="medium"
          cursor="pointer"
          onClick={() => dispatch({ type: DUPLICATE_ITEM, payload: item.cartId })}
        >
          + Duplicate
          <Icon ml="4" color="black" name="info" />
        </Text>
      </Flex>
      <Box
        cursor="pointer"
        w="4rem"
        h="4rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="50%"
        position="absolute"
        top="14px"
        right="18px"
        backgroundColor="#EDEDED"
      >
        <Text
          fontSize="2rem"
          fontFamily="black"
          color="#000"
          onClick={() => dispatch({ type: REMOVE_FROM_CART, payload: item.cartId })}
        >
          X
        </Text>
      </Box>
    </Box>
  )
}

export { CartItem }

const Type = ({ name, type }) => {
  return (
    <Text
      py="1.2rem"
      borderBottom="1px solid #F4F4F4"
      d="flex"
      fontFamily="medium"
      fontSize="1.2rem"
      justifyContent="space-between"
      textTransform="capitalize"
    >
      {name}
      <Text as="span" fontFamily="light">
        {type}
      </Text>
    </Text>
  )
}
