import React from 'react'
import { Box } from '@chakra-ui/core'

function PageWrapper(props) {
  const { children } = props
  return (
    <Box maxW="1280px" mx="auto" px="16" {...props}>
      {children}
    </Box>
  )
}

export { PageWrapper }
