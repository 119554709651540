import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import commafy from 'commafy'
import {
  Box,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Spinner,
  Flex,
  Text,
  Image,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/core'
import { toast } from 'react-toastify'
import { useQuery } from 'react-query'

import { Link } from 'react-router-dom'
import { PageWrapper } from '../components/layout/PageWrapper'
import { AuthContext, UPDATE } from '../context/auth'
import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validateAddress,
  validatePhone,
  formatDeliveryDate,
} from '../utils'
import { FormError } from '../components/common'

import instance from '../network/axios'

import { getOrders } from '../network/user'

import '../index.css'

function Profile() {
  const { authState, dispatch } = useContext(AuthContext)

  const { isLoading, data } = useQuery(['orders', authState.user.id], getOrders)

  const {
    jwt,
    user: { firstname: firstName, lastname: lastName, email, phone, _id: id },
  } = authState

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      firstName,
      lastName,
      email,
      phone,
      address: 'No Address',
    },
  })

  const [editLoading, setEditLoading] = useState(true)
  const [accountSubmitLoading, setAccountSubmitLoading] = useState(false)

  const onAccountSubmit = async (accData) => {
    setAccountSubmitLoading(true)
    try {
      const user = await instance.put(`/users/${id}`, accData, {
        headers: { Authorization: `Bearer ${jwt}` },
      })
      dispatch({ type: UPDATE, payload: { jwt, user: user.data } })
      toast(`Profile Updated`)
      setAccountSubmitLoading(false)
    } catch (error) {
      toast(`Error occured`)
      setAccountSubmitLoading(false)
    }
  }

  const BoxAccordion = styled(motion.div)`
    @media (max-width: 700px) {
      margin-top: 20px;
    }
  `

  return (
    <Box borderTop="1px solid #F4F4F4">
      <PageWrapper
        mb="10rem"
        py="2rem"
        d="flex"
        flexDirection={['column-reverse', 'column-reverse', 'row']}
        justifyContent="space-between"
      >
        <Tabs
          flex="1"
          borderBottom="1px solid #F4F4F4"
          mt={['3rem', '3rem', '0']}
          mr={['0', '0', '4.3rem']}
        >
          <TabList>
            <Tab
              _focus={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
              p="1.5rem"
              fontSize={['1.2rem', '1.5rem', '1.8rem']}
              bg="white"
              borderTop="none"
              borderLeft="none"
              borderRight="none"
              borderBottom="2px solid #F4F4F4"
              borderColor="#F4F4F4"
              borderWidth="2px"
              fontFamily="medium"
            >
              Order history
            </Tab>
            <Tab
              _focus={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}
              p="1.5rem"
              fontSize={['1.2rem', '1.5rem', '1.8rem']}
              bg="white"
              borderTop="none"
              borderLeft="none"
              borderRight="none"
              borderBottom="2px solid #F4F4F4"
              borderColor="#F4F4F4"
              borderWidth="2px"
              fontFamily="medium"
            >
              Manage your information
            </Tab>
          </TabList>

          <TabPanels mt="3rem">
            <TabPanel>
              {isLoading ? (
                <Spinner />
              ) : (
                <Accordion allowToggle defaultChecked={false}>
                  {data?.reverse().map((order, index) => (
                    <AccordionItem
                      key={order.id}
                      defaultIsOpen={false}
                      _last={{ borderBottom: 'none' }}
                    >
                      <AccordionHeader
                        bg="#fff"
                        shadow="none"
                        border="none"
                        _hover={{ background: '#fff' }}
                        _focus={{
                          background: '#fff',
                        }}
                      >
                        <Flex
                          padding="2rem 3rem"
                          w="850px"
                          justify="space-between"
                          backgroundColor="#E2EEFB"
                          border="1px solid #177BE2"
                          wrap="wrap"
                        >
                          <h2
                            style={{
                              border: '1px solid #177BE2',
                              padding: '10px',
                              borderRadius: '40%',
                            }}
                          >
                            {index + 1}
                          </h2>
                          <BoxAccordion>
                            <Box textAlign="left">
                              <Text fontFamily="medium" fontSize="1.4rem">
                                ORDER PLACED
                              </Text>
                              <Text fontFamily="heavy" fontSize="1.8rem">
                                {moment(order.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                              </Text>
                            </Box>
                          </BoxAccordion>
                          <BoxAccordion>
                            <Box textAlign="left">
                              <Text fontFamily="medium" fontSize="1.4rem">
                                TOTAL
                              </Text>
                              <Text fontFamily="heavy" fontSize="1.8rem">
                                ₦{commafy(order.total)}
                              </Text>
                            </Box>
                          </BoxAccordion>
                          <BoxAccordion>
                            <Box textAlign="left">
                              <Text fontFamily="medium" fontSize="1.4rem">
                                SHIPPED TO
                              </Text>
                              <Text fontFamily="heavy" fontSize="1.8rem">
                                {order.customerInfo.firstname}
                              </Text>
                            </Box>
                          </BoxAccordion>
                          <BoxAccordion>
                            <Box textAlign="left">
                              <Text fontFamily="medium" fontSize="1.4rem">
                                DELIVERY DATE
                              </Text>
                              <Text fontFamily="heavy" fontSize="1.8rem">
                                {order.items[0].shipping.name === 'Express'
                                  ? formatDeliveryDate(order.createdAt, 3)
                                  : formatDeliveryDate(order.createdAt, 7)}
                              </Text>
                            </Box>
                          </BoxAccordion>
                        </Flex>
                        <AccordionIcon bg="#fff" color="#d8d8d8" outline="none" />
                      </AccordionHeader>
                      <AccordionPanel fontFamily="Avenir Light">
                        <Box padding="2rem 3rem" w="850px" maxW="100%" border="1px solid #177BE2">
                          {order.items.map((item) => (
                            <Flex mt="3rem" justify="space-between" wrap="wrap">
                              <Image
                                w="140px"
                                src={item.product?.images[0]?.url}
                                alt="Product name"
                              />
                              <BoxAccordion>
                                <Box>
                                  <Text fontFamily="heavy" fontSize="1.8rem">
                                    {item.product.name}
                                  </Text>
                                  <Text fontFamily="light" fontSize="1.4rem">
                                    {item.product.choices}
                                  </Text>
                                </Box>
                              </BoxAccordion>
                              <BoxAccordion>
                                <Box>
                                  <Link to={`/products/${item.product.id}/order`}>
                                    <Button
                                      backgroundColor="#fff"
                                      padding="2rem 2rem"
                                      border="1px solid #FD3B90"
                                      color="#FD3B90"
                                      fontFamily="heavy"
                                      fontSize="1.4rem"
                                    >
                                      VIEW ITEM
                                    </Button>
                                  </Link>
                                </Box>
                              </BoxAccordion>
                            </Flex>
                          ))}
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              )}
            </TabPanel>
            <TabPanel>
              <Box
                p="3.2rem"
                mt="5rem"
                border="1px solid #F4F4F4"
                boxShadow="0px 3px 6px #00000010"
              >
                <Heading mb="3.8rem" w="full" fontFamily="heavy" fontSize="2.2rem">
                  Account Identity
                </Heading>
                <Box as="form" onSubmit={handleSubmit(onAccountSubmit)}>
                  <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={10}>
                    <FormControl>
                      <FormLabel
                        fontFamily="book"
                        fontSize="1.4rem"
                        htmlFor="state"
                        isInvalid={errors.firstName}
                      >
                        First name{' '}
                        <FormError>{errors.firstName && errors.firstName.message}</FormError>
                      </FormLabel>
                      <Input
                        isDisabled={editLoading}
                        mt="0.9rem"
                        borderColor="#F4F4F4"
                        border="1px solid #F4F4F4"
                        fontFamily="book"
                        fontSize="1.4rem"
                        p={8}
                        id="state"
                        ref={register(validateFirstName)}
                        name="firstName"
                      />
                    </FormControl>
                    <FormControl isInvalid={errors.lastName}>
                      <FormLabel fontFamily="book" fontSize="1.4rem" htmlFor="region">
                        Last name{' '}
                        <FormError>{errors.lastName && errors.lastName.message}</FormError>
                      </FormLabel>
                      <Input
                        isDisabled={editLoading}
                        mt="0.9rem"
                        borderColor="#F4F4F4"
                        border="1px solid #F4F4F4"
                        fontFamily="book"
                        fontSize="1.4rem"
                        p={8}
                        id="region"
                        ref={register(validateLastName)}
                        name="lastName"
                      />
                    </FormControl>
                    <FormControl isInvalid={errors.email}>
                      <FormLabel fontFamily="book" fontSize="1.4rem" htmlFor="state">
                        Email address <FormError>{errors.email && errors.email.message}</FormError>
                      </FormLabel>
                      <Input
                        isDisabled={editLoading}
                        mt="0.9rem"
                        borderColor="#F4F4F4"
                        border="1px solid #F4F4F4"
                        fontFamily="book"
                        fontSize="1.4rem"
                        p={8}
                        id="state"
                        ref={register(validateEmail)}
                        name="email"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        fontFamily="book"
                        fontSize="1.4rem"
                        htmlFor="region"
                        isInvalid={errors.phone}
                      >
                        Phone Number <FormError>{errors.phone && errors.phone.message}</FormError>
                      </FormLabel>
                      <Input
                        isDisabled={editLoading}
                        mt="0.9rem"
                        borderColor="#F4F4F4"
                        border="1px solid #F4F4F4"
                        fontFamily="book"
                        fontSize="1.4rem"
                        p={8}
                        name="phone"
                        id="region"
                        ref={register(validatePhone)}
                      />
                    </FormControl>
                  </SimpleGrid>
                  <FormControl mt="1.6rem" isInvalid={errors.address}>
                    <FormLabel fontFamily="book" fontSize="1.4rem" htmlFor="region">
                      Address <FormError>{errors.address && errors.address.message}</FormError>
                    </FormLabel>
                    <Textarea
                      isDisabled={editLoading}
                      mt="0.9rem"
                      borderColor="#F4F4F4"
                      border="1px solid #F4F4F4"
                      fontFamily="book"
                      fontSize="1.4rem"
                      p={8}
                      id="region"
                      name="address"
                      ref={register(validateAddress)}
                    />
                  </FormControl>
                  {editLoading ? (
                    <Button
                      type="button"
                      mt="2rem"
                      border="none"
                      py="2rem"
                      fontFamily="book"
                      variantColor="blue"
                      bg="#177BE2"
                      w="full"
                      borderRadius="0"
                      maxW="244px"
                      fontSize="1.3rem"
                      onClick={(e) => {
                        e.preventDefault()
                        setEditLoading(false)
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      isLoading={accountSubmitLoading}
                      mt="2rem"
                      type="submit"
                      border="none"
                      py="2rem"
                      fontFamily="book"
                      variantColor="blue"
                      bg="#177BE2"
                      w="full"
                      borderRadius="0"
                      maxW="244px"
                      fontSize="1.3rem"
                    >
                      Save
                    </Button>
                  )}
                </Box>
              </Box>
              {/* <Box
                p="3.2rem"
                mt="5rem"
                border="1px solid #F4F4F4"
                boxShadow="0px 3px 6px #00000010"
              >
                <Heading mb="3.8rem" w="full" fontFamily="heavy" fontSize="2.2rem">
                  Update Password
                </Heading>
                <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={10}>
                  <FormControl>
                    <FormLabel fontFamily="book" fontSize="1.4rem" htmlFor="state">
                      Old Password
                    </FormLabel>
                    <Input
                      mt="0.9rem"
                      borderColor="#F4F4F4"
                      border="1px solid #F4F4F4"
                      fontFamily="book"
                      fontSize="1.4rem"
                      p={8}
                      id="state"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontFamily="book" fontSize="1.4rem" htmlFor="region">
                      New Password
                    </FormLabel>
                    <Input
                      mt="0.9rem"
                      borderColor="#F4F4F4"
                      border="1px solid #F4F4F4"
                      fontFamily="book"
                      fontSize="1.4rem"
                      p={8}
                      id="region"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontFamily="book" fontSize="1.4rem" htmlFor="region">
                      Confirm New Password
                    </FormLabel>
                    <Input
                      mt="0.9rem"
                      borderColor="#F4F4F4"
                      border="1px solid #F4F4F4"
                      fontFamily="book"
                      fontSize="1.4rem"
                      p={8}
                      id="region"
                    />
                  </FormControl>
                </SimpleGrid>
                <Button
                  border="none"
                  py="2rem"
                  fontFamily="book"
                  variantColor="blue"
                  bg="#177BE2"
                  w="full"
                  borderRadius="0"
                  maxW="244px"
                  fontSize="1.3rem"
                  mt="2rem"
                >
                  Save
                </Button>
              </Box> */}
            </TabPanel>
          </TabPanels>
        </Tabs>

        {/* <Box>
          <Box
            py="2rem"
            pos={['static', 'static', 'sticky']}
            top="0"
            w={['100%', '100%', '280px']}
            boxShadow="0px 3px 6px #00000010;"
          >
            <Heading px="2rem" py="1rem" fontSize="1.7rem" fontFamily="heavy">
              Account identity
            </Heading>
            <List>
              <ListItem borderBottom="1px solid #F4F4F4" px="2rem" py="1rem">
                <Heading fontFamily="medium" fontSize="1.2rem">
                  Name
                </Heading>
                <Text
                  color="#A0A0A0"
                  d="flex"
                  justifyContent="space-between"
                  fontFamily="light"
                  mt="2"
                  fontSize="1.2rem"
                >
                  {username}
                  <Text fontFamily="light" as="span" color="brand.blue">
                    Edit
                  </Text>
                </Text>
              </ListItem>
              <ListItem borderBottom="1px solid #F4F4F4" px="2rem" py="1rem">
                <Heading fontFamily="medium" fontSize="1.2rem">
                  Email address
                </Heading>
                <Text
                  color="#A0A0A0"
                  d="flex"
                  justifyContent="space-between"
                  fontFamily="light"
                  mt="2"
                  fontSize="1.2rem"
                >
                  {email}
                  <Text fontFamily="light" as="span" color="brand.blue">
                    Edit
                  </Text>
                </Text>
              </ListItem>
              <ListItem px="2rem" py="1rem">
                <Heading fontFamily="medium" fontSize="1.2rem">
                  Password
                </Heading>
                <Text
                  color="#A0A0A0"
                  d="flex"
                  justifyContent="space-between"
                  fontFamily="light"
                  mt="2"
                  fontSize="1.2rem"
                >
                  ***********
                  <Text fontFamily="light" as="span" color="brand.blue">
                    Edit
                  </Text>
                </Text>
              </ListItem>
            </List>
          </Box>
          <Box
            px="2rem"
            mt="2rem"
            py="2rem"
            pos={['static', 'static', 'sticky']}
            top="0"
            w={['100%', '100%', '280px']}
            boxShadow="0px 3px 6px #00000010;"
          >
            <Heading py="1rem" fontSize="1.7rem" fontFamily="heavy">
              Address book
            </Heading>
            <Text fontSize="1.2rem" fontFamily="light" color="#A0A0A0" lineHeight="1.5">
              6 Olamide street, Anthony Estate, Lagos, Nigeria.
            </Text>
            <Text mt="1.6rem" fontSize="1.2rem" fontFamily="light" color="brand.blue">
              Edit Address
            </Text>
          </Box>
        </Box> */}
      </PageWrapper>
    </Box>
  )
}

export { Profile }
