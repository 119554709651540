import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'

import { Box, Text, Divider, Heading } from '@chakra-ui/core'
import { PageWrapper } from '../components/layout'
import { Feel } from '../components/Feel'
import { BestSellers, Trusted, Images, Featured, Ideas, Banner, Steps } from '../components/home'
import { HomeSkeleton } from '../components/skeletons'

import { getHomeData } from '../network/home'
import Newsletter from '../components/Newsletter'

function Home() {
  const { isLoading, data, isError } = useQuery('home', getHomeData)

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    let newsTimeout
    const ModalHasShownOnce = localStorage.getItem('showed_modal')

    if (!ModalHasShownOnce) {
      localStorage.setItem('showed_modal', 'true')
      newsTimeout = setTimeout(() => {
        setShowModal(true)
      }, 1000)
    }

    return () => {
      clearTimeout(newsTimeout)
    }
  }, [])

  if (isLoading) {
    return <HomeSkeleton />
  }

  if (isError) {
    return <p>Error Occured</p>
  }

  return (
    <>
      <Banner isLoading={isLoading} slides={data.slider} categories={data.categories} />
      <Box>
        <Steps />
        <Featured featured={data.featured.first} />
        <BestSellers topSelling={data.featured.second} tags={data.tags} />
        <Trusted />
        <Ideas />
        <PageWrapper
          d="flex"
          flexDirection={['column', 'column', 'row']}
          py="5rem"
          justifyContent="space-between"
        >
          <Heading
            w={['100%', '100%', '50%']}
            textAlign={['center', 'center', 'left']}
            mb="3rem"
            fontWeight="500"
            fontFamily="light"
            fontSize={['4rem', '4rem', '4.6rem']}
          >
            We offer outstanding immersive experiences to all, wherever.
          </Heading>
          <Box flex maxW={['100%', '100%', '374px']}>
            <Text
              fontFamily="light"
              fontSize="1.2rem"
              lineHeight="2"
              textAlign={['center', 'center', 'left']}
            >
              Well-made . Well-designed . Packaged in a way that people can use. And not just use
              it, share it. We believe in the esthetics, the imaginative that stops you in your
              tracks. We believe in light-as-air technology, as smooth as real life.
            </Text>
            <Divider mt="3rem" mx={['auto', 'auto', '0']} w="40%" />
          </Box>
        </PageWrapper>
        <Images images={data.showcase} />
        <Feel />
        <Newsletter showModal={showModal} setShowModal={setShowModal} />
      </Box>
    </>
  )
}

export { Home }
