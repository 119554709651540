import instance from './axios'

export const getProduct = async (key, id) => {
  const { data } = await instance.get(`/products/${id}`)

  return data
}

export const getProducts = async () => {
  const { data } = await instance.get(`/products`)

  return data
}

export const getAboutPageProducts = async () => {
  const { data } = await instance.get(`/products?_limit=3`)

  return data
}

export const getCategoryProducts = async (key, id) => {
  const { data } = await instance.get(`/products?category=${id}`)

  return data
}

export const getTag = async (key, id) => {
  const { data } = await instance.get(`/tags/${id}`)

  return data
}
