import React from 'react'
import { Box, Flex, Heading, SimpleGrid, Image } from '@chakra-ui/core'

import imagearr from '../../assets/images/trusted'

function Trusted() {
  return (
    <Box bg="brand.blue" d={['none', 'block']}>
      <Flex
        flexDirection={['column', 'column', 'row']}
        maxW="1280px"
        mx="auto"
        px="16"
        mt="6rem"
        py="6rem"
        justify="space-between"
      >
        <Heading
          color="white"
          fontFamily="light"
          fontSize="3.6rem"
          w={['100%', '100%', '260px']}
          textAlign={['center', 'center', 'left']}
          flexShrink="0"
          mb="35px"
        >
          Trusted by biggest players in the game.
        </Heading>
        <SimpleGrid
          style={{ placeItems: 'center' }}
          w={['100%', '100%', '100%', '70%']}
          minChildWidth="100px"
          spacing={10}
        >
          {imagearr.map((image) => (
            <Image key={image} src={image} />
          ))}
        </SimpleGrid>
      </Flex>
    </Box>
  )
}

export { Trusted }
