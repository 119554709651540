import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import { Image, Box, Link as CLink } from '@chakra-ui/core'

import { PageWrapper } from './PageWrapper'
import { Product } from '../common/Product'

import arrow from '../../assets/images/icons/right-arrow.svg'

function Megamenu({ setVisibility, categories }) {
  const [activeCategory, setActiveCategory] = useState({})
  const [activeProduct, setActiveProduct] = useState(null)

  return (
    <AnimatePresence>
      <PageWrapper
        position="relative"
        onMouseEnter={() => setVisibility(true)}
        onMouseLeave={() => setVisibility(false)}
      >
        <motion.div
          style={{
            position: 'absolute',
            top: '1px',
            zIndex: 100,
            width: '100%',
          }}
          initial={{
            opacity: 0,
            height: 0,
          }}
          animate={{
            opacity: 1,
            height: 500,
          }}
          exit={{
            opacity: 0,
            height: 0,
          }}
          transition={{ duration: 0.5 }}
        >
          <Box w="100%" h="100%" display="flex" shadow="0px 3px 2px #00000021" background="#fff">
            <Box w="250px" h="100%" overflowY="scroll">
              {categories.map((item) => (
                <CLink
                  as={Link}
                  to={`/products/${item.id}`}
                  key={item.id}
                  d="flex"
                  justifyContent="space-between"
                  fontFamily="Avenir Medium"
                  fontSize="1.4rem"
                  padding="1rem 2.5rem"
                  color="#000"
                  _hover={{
                    background: '#F3F4F6',
                    color: '#000',
                  }}
                  onMouseEnter={() => setActiveCategory(item)}
                  onClick={() => setVisibility(false)}
                >
                  {item.name}
                  <Image src={arrow} alt="Arrow" maxW="1rem" />
                </CLink>
              ))}
            </Box>
            <Box w="300px" overflowY="scroll" h="100%" background="#F3F4F6">
              <Box w="250px" h="100%">
                {activeCategory.products
                  ?.sort((a, b) => {
                    return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
                  })
                  .map((item) => (
                    <CLink
                      as={Link}
                      to={`/products/${item.id}/order`}
                      key={item.id}
                      d="flex"
                      w="100%"
                      justifyContent="space-between"
                      fontFamily="Avenir Medium"
                      fontSize="1.4rem"
                      padding="1rem 2.5rem"
                      color="#000"
                      _hover={{
                        background: '#fff',
                        color: '#000',
                      }}
                      onMouseEnter={() => setActiveProduct(item)}
                      onClick={() => setVisibility(false)}
                    >
                      {item.name}
                    </CLink>
                  ))}
              </Box>
            </Box>
            <Box flex={1} d="flex" padding="2.5rem">
              <Box w="245px" marginRight="2.7rem">
                {activeProduct && <Product details item={activeProduct} maxW="242px" />}
              </Box>
            </Box>
          </Box>
        </motion.div>
      </PageWrapper>
    </AnimatePresence>
  )
}

export { Megamenu }
