import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Box, Button, Input, Text } from '@chakra-ui/core'
import styled from 'styled-components'

import newsletter from '../assets/images/newsletter.png'

import { subToNewsletter } from '../network/home'

function Newsletter({ showModal, setShowModal }) {
  const [email, setEmail] = useState('')

  const subscribe = async () => {
    try {
      if (!email) {
        toast('Please enter a valid email')
        return
      }

      await subToNewsletter(email)

      setShowModal(false)
      toast('Subscribed Successfully')
    } catch (error) {
      toast('Failed to subscribe. Please try again later')
    }
  }

  return (
    <>
      {showModal && (
        <Box
          w="100%"
          h="100vh"
          position="fixed"
          top="0"
          zIndex="100"
          backgroundColor="rgba(0,0,0, .4)"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            backgroundColor="#fff"
            w={['350px', '450px', '550px', '820px']}
            h={['350px', '400px', '450px', '565px']}
            textAlign="center"
            position="relative"
          >
            <ImageBox
              backgroundImage={`url(${newsletter})`}
              backgroundSize="100%"
              backgroundRepeat="no-repeat"
              height="200px"
              textAlign="right"
            >
              <button
                type="button"
                onClick={() => setShowModal(false)}
                style={{
                  padding: '5px',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  outline: '0',
                  border: '0',
                  background: '#fff',
                  margin: '12px',
                  boxShadow: '0 1rem 3rem rgba(0, 0, 0, 0.175)',
                }}
              >
                X
              </button>
            </ImageBox>

            <Text fontFamily="Avenir Black" fontSize={['2rem', '2rem', '2.5rem', '3rem']}>
              Thanks for stopping by!
            </Text>
            <Text
              fontFamily="Avenir Light"
              fontSize={['1.5rem', '1.9rem', '2.2rem', '2.2rem']}
              maxW={['300px', '400px', '450px', '495px']}
              mx="auto"
              mt="2rem"
            >
              Leave your email address below for promotions, new products, and our informative and
              quirky newsletter.
            </Text>
            <Box
              mx="auto"
              mt="2.8rem"
              shadow="0px 3px 6px #00000029"
              w={['300px', '350px', '450px', '467px']}
              h="67px"
              backgroundColor="#fff"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding="1.2rem"
            >
              <Input
                border="none"
                outline="none"
                height="100%"
                fontFamily="Avenir Light"
                fontSize="1.6rem"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                _focus={{
                  border: 'none',
                  outline: 'none',
                }}
              />
              <Button
                w="135px"
                h="45px"
                fontSize="1.5rem"
                backgroundColor="#177BE2"
                display="flex"
                color="#fff"
                border="none"
                outline="none"
                _hover={{
                  background: '#177BE2',
                  color: '#fff',
                  opacity: 0.9,
                }}
                onClick={subscribe}
              >
                Submit
              </Button>
            </Box>
            <Text
              onClick={() => setShowModal(false)}
              cursor="pointer"
              textDecoration="underline"
              fontSize="1.5rem"
              fontFamily="Avenir Light"
              position="absolute"
              bottom="4rem"
              left="50%"
              transform="translateX(-50%)"
            >
              No Thanks
            </Text>
          </Box>
        </Box>
      )}
    </>
  )
}

export default Newsletter

const ImageBox = styled(Box)`
  @media (max-width: 900px) {
    height: 80px !important;
  }
`
