import React from 'react'
import styled from 'styled-components'

function Spinner() {
  return (
    <Wrapper>
      <div className="sp sp-circle" />
    </Wrapper>
  )
}

export { Spinner }

const Wrapper = styled.div`
  .sp {
    width: 32px;
    height: 32px;
    clear: both;
    margin: 20px auto;
  }

  /* Spinner Circle Rotation */
  .sp-circle {
    border: 4px rgba(0, 0, 0, 0.25) solid;
    border-top: 4px black solid;
    border-radius: 50%;
    -webkit-animation: spCircRot 0.6s infinite linear;
    animation: spCircRot 0.6s infinite linear;
  }

  @-webkit-keyframes spCircRot {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
  @keyframes spCircRot {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`
