import adobe from './adobe.svg'
import disney from './disney.svg'
import godaddy from './godaddy.svg'
import google from './google.svg'
import ikea from './ikea.svg'
import microsoft from './microsoft.svg'
import nasa from './nasa.svg'
import nike from './nike.svg'
import yahoo from './yahoo.svg'
import tmobile from './t-mobile.svg'

const imagearr = [adobe, disney, godaddy, google, ikea, microsoft, nasa, nike, yahoo, tmobile]

export default imagearr
