import axios from 'axios'

// const { token } = JSON.parse(localStorage.getItem('user')) || { token: null }

const instance = axios.create({
  baseURL: 'https://admin.warnerprint.com',
  // baseURL: 'http://localhost:1337',
})

// instance.defaults.headers.common.Authorization = `Bearer ${token}`

export default instance
