import instance from './axios'

export const saveOrder = async (user, total, customerInfo, deliveryInfo, items) => {
  const { data } = await instance.post('/orders', {
    user,
    total,
    customerInfo,
    deliveryInfo,
    items,
  })

  return data
}
