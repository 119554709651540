import instance from './axios'

const signup = async (body) => {
  const { data } = await instance.post('/auth/local/register', body)

  await instance.post('/email/send-auth-code', { email: data.user.email })

  return data
}

const verify = async (body) => {
  const { data } = await instance.post('/email/verify', body)

  return data
}

const login = async (body) => {
  const { data } = await instance.post('/auth/local', body)

  return data
}

const resendCode = async (email) => {
  await instance.post('/email/send-auth-code', { email })
}

export { signup, login, verify, resendCode }
