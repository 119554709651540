import React, { useEffect, useContext } from 'react'
import { ThemeProvider } from '@chakra-ui/core'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { ToastContainer } from 'react-toastify'

import { Header, Footer } from '../layout'

import theme from '../../theme'
// Routes
import {
  Home,
  SignUp,
  Login,
  ForgotPassword,
  Order,
  Profile,
  Checkout,
  Category,
  Tag,
  Verify,
  AboutUs,
  ContactUs,
} from '../../routes'

import { CartContextProvider } from '../../context/cart'
import { AuthContext, AuthContextProvider } from '../../context/auth'

function App() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <AuthContextProvider>
      <CartContextProvider>
        <ThemeProvider theme={theme}>
          <ToastContainer toastClassName="notification" progressClassName="notification-bar" />
          <Header />
          <AnimatePresence exitBeforeEnter>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about-us" component={AboutUs} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/verify" component={Verify} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <PrivateRoute exact path="/profile" component={Profile} />
              <Route exact path="/products/:id/order" component={Order} />
              <Route exact path="/products/:type" component={Category} />
              <Route exact path="/products/tag/:type" component={Tag} />
              <Route path="/checkout" component={Checkout} />
              <Route exact path="/contact-us" component={ContactUs} />
            </Switch>
          </AnimatePresence>
          <Footer />
        </ThemeProvider>
      </CartContextProvider>
    </AuthContextProvider>
  )
}

function PrivateRoute({ path, exact, component }) {
  const { authState } = useContext(AuthContext)

  const isLoggedIn = authState?.jwt
  const isVerified = authState?.user?.verified

  return (
    <>
      {isLoggedIn && isVerified ? (
        <Route path={path} exact={exact} component={component} />
      ) : isLoggedIn && !isVerified ? (
        <Redirect to="/verify" />
      ) : (
        <Redirect to="/login" />
      )}
    </>
  )
}

export default App
