import axios from 'axios'
import instance from './axios'

export const getHomeData = async () => {
  const [slider, featuredProducts, showcase, tags, categories] = await axios.all([
    instance.get('/homepage-slider'),
    instance.get('/homepage-featured-sections'),
    instance.get('/homepage-design-showcase'),
    instance.get('/tags'),
    instance.get('/categories?_limit=7'),
  ])

  return {
    slider: slider.data.slide,
    featured: {
      first: featuredProducts.data.first,
      second: featuredProducts.data.second,
    },
    tags: tags.data,
    showcase: showcase.data.images,
    categories: categories.data,
  }
}

export const subToNewsletter = async (email) => {
  const { data } = await instance.post('/email/subscribe', {
    email,
  })

  return data
}
