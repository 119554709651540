import { theme } from '@chakra-ui/core'

// Let's say you want to add custom colors
const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    brand: {
      blue: '#177BE2',
      pink: '#FD3B90',
    },
  },
  fonts: {
    light: '"Avenir Light", sans-serif',
    book: '"Avenir Book", sans-serif',
    medium: '"Avenir Medium", sans-serif',
    roman: '"Avenir Roman", sans-serif',
    heavy: '"Avenir Heavy", sans-serif',
    black: '"Avenir Black", sans-serif',
  },
}

export default customTheme
