import { ADD_TO_CART, DUPLICATE_ITEM, REMOVE_FROM_CART, EMPTY_CART } from './types'

// Services
function addToCart(product, cart) {
  return [...cart, product]
}

function removeFromCart(id, cart) {
  return cart.filter((item) => item.cartId !== id)
}

function duplicate(id, cart) {
  const itemToCopy = cart.find((item) => item.cartId === id)
  if (itemToCopy) {
    return [...cart, itemToCopy]
  }

  return [...cart]
}

function emptyCart() {
  return []
}

// Reducer
export const cartReducer = (state, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return addToCart(action.payload, state)
    case REMOVE_FROM_CART:
      return removeFromCart(action.payload, state)
    case DUPLICATE_ITEM:
      return duplicate(action.payload, state)
    case EMPTY_CART:
      return emptyCart(state)
    default:
      return state
  }
}
