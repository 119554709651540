import React, { useContext, useRef, useState } from 'react'
import {
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Box,
  Text,
  Button,
  Link as CLink,
} from '@chakra-ui/core'
import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError } from '../components/common'

import { validateFirstName, validateLastName, validateEmail, validatePassword } from '../utils'
import { signup } from '../network/auth'
import { AuthContext, SIGNUP } from '../context/auth'

const SignUp = () => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { register, handleSubmit, errors, watch } = useForm()
  const { dispatch } = useContext(AuthContext)
  const password = useRef({})

  password.current = watch('password', '')

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      data.username = data.email
      const res = await signup(data)

      if (res.user.confirmed) {
        setLoading(false)
        dispatch({ type: SIGNUP, payload: res })
        toast('Signup Successful. Check your email for PIN')
        history.push('/verify')
      }
    } catch (error) {
      setLoading(false)
      const message = error?.response?.data?.message[0]?.messages[0]?.message || 'Signup failed'
      toast(message.replace('Identifier', 'Email'))
    }
  }

  return (
    <>
      <SimpleGrid maxW="720px" mx={['3rem', 'auto']} mt={70} mb={100}>
        <Text fontSize="32px" fontFamily="black">
          Create an Account
        </Text>
        <Box as="form" onSubmit={handleSubmit(onSubmit)} py={8}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={10}>
            <FormControl p={2} isInvalid={errors.firstname}>
              <FormLabel htmlFor="firstname" as="" fontSize="lgx1" fontFamily="book">
                First name <FormError>{errors.firstname && errors.firstname.message}</FormError>
              </FormLabel>
              <Input
                focusBorderColor="#177BE2"
                id="firstname"
                placeholder="Enter first name"
                fontSize="15px"
                p={10}
                borderColor="#ddd"
                fontFamily="book"
                name="firstname"
                ref={register(validateFirstName)}
              />
            </FormControl>
            <FormControl p={2} isInvalid={errors.lastname}>
              <FormLabel htmlFor="lastname" fontSize="lgx1" fontFamily="book">
                Last name <FormError>{errors.lastname && errors.lastname.message}</FormError>
              </FormLabel>
              <Input
                focusBorderColor="#177BE2"
                type="lastname"
                id="lastname"
                placeholder="Enter Last name"
                fontSize="15px"
                p={10}
                borderColor="#ddd"
                fontFamily="book"
                name="lastname"
                ref={register(validateLastName)}
              />
            </FormControl>
            <FormControl p={2} isInvalid={errors.email}>
              <FormLabel htmlFor="email" fontSize="lgx1" fontFamily="book">
                Email address <FormError>{errors.email && errors.email.message}</FormError>
              </FormLabel>
              <Input
                focusBorderColor="#177BE2"
                type="email"
                id="email"
                placeholder="Enter email address"
                fontSize="15px"
                p={10}
                borderColor="#ddd"
                fontFamily="book"
                name="email"
                ref={register(validateEmail)}
              />
            </FormControl>
            <FormControl p={2} isInvalid={errors.delivery}>
              <FormLabel htmlFor="delivery" fontSize="lgx1" fontFamily="book">
                Phone
              </FormLabel>
              <Input
                focusBorderColor="#177BE2"
                type="text"
                id="phone"
                placeholder="Enter your delivery address"
                fontSize="15px"
                p={10}
                borderColor="#ddd"
                fontFamily="book"
                name="phone"
                ref={register()}
              />
            </FormControl>
            <FormControl p={2} isInvalid={errors.password}>
              <FormLabel htmlFor="password" fontSize="lgx1" fontFamily="book">
                Password <FormError>{errors.password && errors.password.message}</FormError>
              </FormLabel>
              <Input
                focusBorderColor="#177BE2"
                type="password"
                id="password"
                placeholder="Enter password"
                fontSize="15px"
                p={10}
                borderColor="#ddd"
                fontFamily="book"
                name="password"
                ref={register(validatePassword)}
              />
            </FormControl>
            <FormControl p={2} isInvalid={errors.confirmPassword}>
              <FormLabel htmlFor="password" fontSize="lgx1" fontFamily="book">
                Confirm password{' '}
                <FormError>{errors.confirmPassword && errors.confirmPassword.message}</FormError>
              </FormLabel>
              <Input
                focusBorderColor="#177BE2"
                type="password"
                id="confirmPassword"
                placeholder="Enter confirm password"
                fontSize="15px"
                p={10}
                borderColor="#ddd"
                fontFamily="book"
                name="confirmPassword"
                ref={register({
                  validate: (value) => value === password.current || 'The passwords do not match',
                })}
              />
            </FormControl>
            <Button
              size="md"
              height="60px"
              width="100%"
              border="2px"
              variantColor="blue"
              fontSize="18px"
              type="submit"
              isLoading={loading}
            >
              Create Account
            </Button>
          </SimpleGrid>
        </Box>
        <Text py={8} fontSize="14px" fontFamily="light">
          Already have an account?{' '}
          <CLink as={Link} color="black" to="/login" fontSize="14px" fontFamily="medium">
            Sign in Now
          </CLink>
        </Text>
        <Text fontSize="14px" fontFamily="light">
          By creating an account, you’re agreeing to our{' '}
          <CLink color="black" href="google.com" fontFamily="medium">
            Terms and Conditions{' '}
          </CLink>
          and{' '}
          <CLink color="black" href="google.com" fontFamily="medium">
            Privacy policy
          </CLink>
          <br />
          <br />
          This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service
          apply.
        </Text>
      </SimpleGrid>
    </>
  )
}

export { SignUp }
