import React from 'react'
import { Box, Flex, Skeleton } from '@chakra-ui/core'

function ProductsSkeleton() {
  return (
    <>
      <Skeleton h="150px" />
      <Flex
        maxW="1280px"
        mx="auto"
        d={['none', 'none', 'flex']}
        alignItems="flex-start"
        justify="space-between"
        pl="16"
      >
        <Skeleton height="500px" width="260px" m="10px" />
        <Box mx="auto">
          <Flex direction={['column', 'column', 'row']} mx="auto">
            <Skeleton h="242px" width="220px" m="10px" />
            <Skeleton h="242px" width="220px" m="10px" />
            <Skeleton h="242px" width="220px" m="10px" />
            <Skeleton h="242px" width="220px" m="10px" />
          </Flex>
        </Box>
      </Flex>
    </>
  )
}

export { ProductsSkeleton }
