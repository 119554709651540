import moment from 'moment'

export function truncateString(str, num) {
  return str.length > num ? `${str.slice(0, num)} ${' ...'}` : str
}

export function titleCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export function roundUp(amount) {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export function formatDeliveryDate(orderDate, duration) {
  return moment(
    moment(moment(orderDate).format('DD-MM-YYYY'), 'DD-MM-YYYY').add(duration, 'days').toDate(),
  ).format('MMMM Do YYYY')
}
