import React, { useContext } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Box, Flex, Text, Heading, Button } from '@chakra-ui/core'

import left from '../../assets/images/icons/left-arrow.svg'
import right from '../../assets/images/icons/right-arrow.svg'
import { AuthContext } from '../../context/auth'

function Banner({ slides, categories }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    nextArrow: <SliderArrow position="right" src={right} alt="Right arrow" />,
    prevArrow: <SliderArrow src={left} alt="Left arrow" />,
  }

  return (
    <Box position="relative">
      <Slider {...settings} style={{ maxHeight: '450px' }}>
        {slides.map((slide) => (
          <Box key={slide.id} h="450px" bgImage={`url(${slide.image.url})`} bgSize="cover">
            <Slide key={slide.id} slide={slide} />
          </Box>
        ))}
      </Slider>
      <BannerNav categories={categories} />
    </Box>
  )
}

export { Banner }

function Slide({ slide }) {
  const { authState } = useContext(AuthContext)

  return (
    <Box pt="6rem" maxW="1280px" mx="auto">
      <Heading
        px={['16']}
        fontSize={['3.7rem', '4.8rem']}
        lineHeight="5rem"
        fontFamily="black"
        w={['95%', '50%']}
      >
        {slide.title.replace('Hello', `Hello ${authState?.user?.firstname || ''}`)}
      </Heading>
      <Text
        lineHeight="1.5"
        maxW="500px"
        fontSize="1.8rem"
        px={['16']}
        my="2rem"
        fontFamily="light"
      >
        {slide.subtitle}
      </Text>

      <Link to="/product">
        <Button
          cursor="pointer"
          mx={['16']}
          fontSize="1.2rem"
          px="2.2rem"
          borderRadius="0"
          height="36px"
          variantColor="blue"
          fontWeight="normal"
          border="none"
          background="brand.blue"
          mb="10rem"
          d="flex"
          alignItems="center"
        >
          {slide.buttonText}
        </Button>
      </Link>
    </Box>
  )
}

function BannerNav({ categories }) {
  return (
    <Box
      d={['none', 'none', 'none', 'block']}
      position="absolute"
      w="100%"
      bottom="0px"
      backgroundColor="rgba(0, 0, 0, 0.4);"
    >
      <Box maxW="1280px" mx="auto">
        <Flex p="2.2rem" maxW="80%" mx="auto" justify="space-around">
          {categories.map((item) => (
            <Link key={item.id} to={`/products/${item.id}`}>
              <BannerLink color="white" fontFamily="light">
                {item.name}
              </BannerLink>
            </Link>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}

const BannerLink = styled(Text)`
  position: relative;
  /* display: flex; */

  &::after {
    content: '';
    width: 0;
    position: absolute;
    height: 1px;
    background: #fff;
    left: 0;
    bottom: -1rem;
    transition: all 0.5s;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }
`

const SliderArrow = styled.img`
  width: 50px;
  height: 50px;
  z-index: 2;

  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
  }

  ${({ position }) => (position === 'right' ? 'right: 0' : 'left: 0')}
`
