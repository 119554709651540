import React from 'react'
import styled from 'styled-components'

import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/core'
import { Link } from 'react-router-dom'

import cart from '../../assets/images/icons/shopping-cart.svg'
import avatar from '../../assets/images/icons/user.svg'

function MobileMenu({ isOpen, onClose, btnRef, categories }) {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <Close />
        <DrawerBody p="0" style={{ overflowY: 'scroll' }}>
          {/* <Box mt="6rem" mx="1rem">
            <InputGroup width="100%">
              <Input
                height="50px"
                borderColor="#D8D8D8"
                px="8"
                fontFamily="Avenir Light"
                placeholder="Find a product…"
                borderRadius="0"
                color="#808080"
              />
              <InputRightElement px="10" height="38px" children={<Icon name="search" />} />
            </InputGroup>
          </Box> */}
          <Box mt="6rem">
            <Accordion allowToggle defaultChecked={false}>
              {categories?.map((item) => (
                <AccordionItem
                  key={item.id}
                  defaultIsOpen={false}
                  _last={{ borderBottom: '1px solid #d8d8d8' }}
                >
                  <AccordionHeader
                    bg="#fff"
                    minH="4rem"
                    shadow="none"
                    border="1px solid #d8d8d8"
                    borderBottom="none"
                    _hover={{ background: '#fff' }}
                    _focus={{
                      background: '#fff',
                    }}
                  >
                    <Box
                      bg="#fff"
                      outline="none"
                      flex="1"
                      textAlign="left"
                      fontFamily="Avenir Medium"
                      fontSize="1.5rem"
                      p="1rem"
                    >
                      {item.name}
                    </Box>
                    <AccordionIcon bg="#fff" color="#d8d8d8" outline="none" />
                  </AccordionHeader>
                  <AccordionPanel fontFamily="Avenir Light" p="2rem">
                    {item.products
                      ?.sort((a, b) => {
                        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
                      })
                      .map((product) => (
                        <Link
                          key={product.id}
                          style={{ display: 'block', padding: '.7rem 0', color: '#000' }}
                          to={`/products/${product.id}/order`}
                        >
                          {product.name}
                        </Link>
                      ))}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
          <Box mt="5rem" mb="5rem">
            <StyledLink to="/profile">
              <img src={avatar} alt="cart" /> Account
            </StyledLink>
            <StyledLink to="/checkout">
              <img src={cart} alt="cart" />
              Cart
            </StyledLink>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default MobileMenu

const Close = styled(DrawerCloseButton)`
  background: transparent;
  outline: none;
  border: none;
  width: 4rem;
`

const StyledLink = styled(Link)`
  width: 100%;
  height: 50px;
  background: #177be2;
  color: #fff;
  display: block;
  font-family: 'Avenir Light';
  display: flex;
  align-items: center;
  padding-left: 2rem;
  margin-top: 0.5rem;
  font-size: 1.6rem;

  img {
    width: 1.7rem;
    margin-right: 1rem;
  }
`
