import React from 'react'
import { Box, Flex, Heading, Image } from '@chakra-ui/core'
import { PageWrapper } from '../layout'
import TriangleRight from '../../assets/images/icons/triangle-right.svg'
import videobg from '../../assets/images/poster.png'

function Ideas() {
  return (
    <Box
      bgImage={`url(${videobg})`}
      maxW="1135px"
      height="560px"
      margin="0 auto"
      bgRepeat="no-repeat"
      bgSize="cover"
      bgPos="center"
    >
      <PageWrapper d="flex" flexDirection="column" alignItems="center" mt="6rem" p="5.6rem">
        <Heading
          maxW="600px"
          textAlign="center"
          fontWeight="500"
          fontFamily="light"
          fontSize={['4rem', '5rem', '7.4rem']}
          textDecoration="underline"
        >
          We turn ideas into experience
        </Heading>
        <Flex mt="2.4rem" justify="center" align="center" bg="white" size="113px" rounded="full">
          <Image src={TriangleRight} size="30px" />
        </Flex>
      </PageWrapper>
    </Box>
  )
}

export { Ideas }
