import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import {
  List,
  ListItem as ChakraLink,
  Heading,
  Flex,
  Image,
  Box,
  Text,
  Input,
  Button,
} from '@chakra-ui/core'

import logo from '../../assets/images/logo.png'
import Newsletter from '../Newsletter'

const ListItem = (props) => {
  const { children } = props
  return (
    <ChakraLink fontFamily="Avenir Light" fontSize="1.2rem" {...props}>
      {children}
    </ChakraLink>
  )
}

function Footer() {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Box bg="black">
        <Flex
          flexDirection={['column', 'column', 'row']}
          maxW="1280px"
          mx="auto"
          px="16"
          justify="space-between"
          color="white"
          p="5rem"
        >
          <Box>
            <Box
              fontWeight="normal"
              fontFamily="Avenir Light"
              fontSize={['1.4rem', '2rem', '2.6rem']}
            >
              Sign up for our newsletter and get a
              <Heading ml="4" d="inline" fontFamily="Avenir Black">
                10% discount!
              </Heading>
            </Box>
            <Text
              mb="3rem"
              mt="2"
              fontFamily="Avenir Light"
              fontSize={['1.2rem', '1.2rem', '1.5rem']}
            >
              You’ll be the first to know about irresistible offers & exclusive print deals!
            </Text>
          </Box>
          <Box maxW="370px" flex="1" p="0.8rem" align="center">
            <Flex bg="white" p="1rem">
              <Input border="none" p="8" placeholder="Enter your email address" fontSize="1.3rem" />

              <Button
                size="md"
                fontSize="1.2rem"
                w="10rem"
                variantColor="blue"
                h="4rem"
                borderRadius="0"
                fontWeight="normal"
                color="#FFFFFF"
                border="none"
                px="2rem"
                background="brand.blue"
                textAlign="center"
                cursor="pointer"
                bg="#177BE2"
              >
                Submit
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box as="footer" w="100%" maxW="1280px" mx="auto" py="8">
        <Flex py="4" width={['100%', '100%', '85%']} justify="space-between" px="16">
          <Box d={['none', 'block', 'block']}>
            <Heading fontFamily="Avenir Medium" fontWeight="500" fontSize="1.6rem" mb="8">
              Products
            </Heading>
            <List spacing={5}>
              <ListItem>
                <Link to="/products/5f5f8c7a9eac7a224853ae5c">Business Cards</Link>
              </ListItem>
              <ListItem>
                <Link to="/products/5f5f8cbc9eac7a224853ae5e">Letterhead</Link>
              </ListItem>
              <ListItem>
                <Link to="/products/5f5f8cd79eac7a224853ae60">Beanded Envelopes</Link>
              </ListItem>
              <ListItem>
                <Link to="/products/5f5f8d009eac7a224853ae62">Greetings Card</Link>
              </ListItem>
            </List>
          </Box>
          <Box mt="16" d={['none', 'none', 'block']}>
            <List spacing={5}>
              <ListItem>
                <Link to="/products/5f5f8d179eac7a224853ae64">Flyers</Link>
              </ListItem>
              <ListItem>
                <Link to="/products/5f5f8d399eac7a224853ae66">Paper Bags</Link>
              </ListItem>
              <ListItem>
                <Link to="/products/5f5f8d5c9eac7a224853ae68">Wedding Stationery</Link>
              </ListItem>
              <ListItem>
                <Link to="/products/5f5f8d789eac7a224853ae6a">Stickers</Link>
              </ListItem>
            </List>
          </Box>
          <Box d={['none', 'none', 'block']}>
            <Heading fontFamily="Avenir Medium" fontWeight="500" fontSize="1.6rem" mb="8">
              Categories
            </Heading>
            <List spacing={5}>
              <ListItem>
                <Link to="/products/tag/5f5f929a9eac7a224853ae90">Promotional Print</Link>
              </ListItem>
              <ListItem>
                <Link to="/products/tag/5f5f92bf9eac7a224853ae92">Stationery</Link>
              </ListItem>
              <ListItem>
                <Link to="/products/tag/5f5f92da9eac7a224853ae94">Gift Items</Link>
              </ListItem>
              <ListItem>
                <Link to="/products/tag/5f5f93059eac7a224853ae96">Packaging</Link>
              </ListItem>
            </List>
          </Box>
          <Box>
            <Heading fontFamily="Avenir Medium" fontWeight="500" fontSize="1.6rem" mb="8">
              About Us
            </Heading>
            <List spacing={5}>
              <ListItem cursor="pointer" _hover={{ color: '#177be2' }}>
                Our blog
              </ListItem>
              <ListItem cursor="pointer" _hover={{ color: '#177be2' }}>
                <Link to="/about-us">About the Company</Link>
              </ListItem>
              <ListItem
                cursor="pointer"
                _hover={{ color: '#177be2' }}
                onClick={() => setShowModal(true)}
              >
                Warner’s LETTER sign up
              </ListItem>
            </List>
          </Box>
          <Box>
            <Heading fontFamily="Avenir Medium" fontWeight="500" fontSize="1.6rem" mb="8">
              Help
            </Heading>
            <List spacing={5}>
              <ListItem>
                <Link to="/contact-us">Contact Us</Link>
              </ListItem>
              {/* <ListItem>Pricing </ListItem>
              <ListItem> FAQs </ListItem> */}
            </List>
          </Box>
        </Flex>
      </Box>
      <Box borderTop="1px solid #dddddd">
        <Flex
          flexDirection={['column', 'column', 'row']}
          maxW="1280px"
          mx="auto"
          align="center"
          justify="space-between"
          padding="3rem 0"
        >
          <Flex align="center" flexDirection={['column', 'column', 'row']}>
            <Link to="/">
              <Image h="38px" src={logo} alt="logo" mb={['1rem', '1rem', '0']} />
            </Link>
            <ListItem
              as="p"
              ml="8"
              pt="2"
              textAlign={['center', 'center', 'left']}
              mb={['2rem', '2rem', '0']}
            >
              © Warner Prints, 5 Yusuf Okunade street, Idiroko Estate. - Registered in Nigeria.
            </ListItem>
          </Flex>
          <Flex align="center">
            <ListItem as="p" ml="8" fontFamily="Avenir Light">
              Terms & Conditions
            </ListItem>
            <ListItem as="p" ml="8" fontFamily="Avenir Light">
              Privacy Policy
            </ListItem>
            <ListItem as="p" ml="8" fontFamily="Avenir Light">
              Sitemap
            </ListItem>
          </Flex>
        </Flex>
      </Box>
      <Newsletter showModal={showModal} setShowModal={setShowModal} />
    </>
  )
}

export { Footer }
