import instance from './axios'

export const sendOrderEmail = async (user, total, customerInfo, deliveryInfo, items) => {
  const { data } = await instance.post('/order/email', {
    user,
    total,
    customerInfo,
    deliveryInfo,
    items,
  })

  return data
}
