import React, { useRef, useState } from 'react'
import { Flex, Text, Box, Link as CLink, Spinner } from '@chakra-ui/core'

import { toast } from 'react-toastify'

import { Link } from 'react-router-dom'

import styled from 'styled-components'

import instance from '../network/axios'

import '../form.css'
import '../font.css'

const user = {
  firstname: '',
  lastname: '',
  company: '',
  subject: '',
  phone: '',
  email: '',
  message: '',
}

function ContactUs() {
  const formRef = useRef(null)
  const [form, setForm] = useState(user)
  const [disabled, setDisabled] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)

  function handleChange(event) {
    const { name, value } = event.target
    setForm({ ...form, [name]: value })
  }

  async function handleSubmit(event) {
    event.preventDefault()
    setDisabled(true)
    setShowSpinner(true)
    try {
      await instance.post('/contacts', form)

      formRef.current.reset()
      setDisabled(false)
      setShowSpinner(false)
      toast("Form submitted successfully, we'll get back to you soon.")
    } catch (e) {
      setDisabled(false)
      setShowSpinner(false)
      toast('An error occured, pls try againn later.')
    }
  }

  return (
    <>
      <Box bg="#eef5fd">
        <Box maxW="1280px" mx="auto" pl={4}>
          <Flex
            p="1rem"
            ml="2rem"
            justify="flex-start"
            alignItems="center"
            h="42px"
            fontSize="1.2rem"
          >
            <Box
              background="#E3EAF1"
              border="1px solid #EDF5FD"
              pl="8px"
              pr="10px"
              py="4.7px"
              mr="2rem"
              style={{ clipPath: 'polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)' }}
            >
              <Text fontFamily="light">
                <CLink as={Link} color="#808080" to="/">
                  Home
                </CLink>
              </Text>
            </Box>
            <Text color="#808080" mr="2.5rem" fontFamily="light">
              Contact us
            </Text>
          </Flex>
        </Box>
      </Box>
      <CustomerService>
        <h1>Customer Service</h1>
      </CustomerService>
      <PageWrapper>
        <ContactForm>
          <div className="wrapper">
            <form onSubmit={handleSubmit} ref={formRef}>
              <h1>Send an email</h1>
              <div className="flex">
                <p className="half1">
                  <br />
                  <input type="text" placeholder="Company" name="company" onChange={handleChange} />
                </p>

                <p className="half2">
                  <br />
                  <input
                    type="text"
                    placeholder="Subject*"
                    name="subject"
                    onChange={handleChange}
                    required
                  />
                </p>

                <p className="half1">
                  <br />
                  <input
                    type="text"
                    placeholder="Firstname*"
                    name="firstname"
                    onChange={handleChange}
                    required
                  />
                </p>

                <p className="half2">
                  <br />
                  <input
                    type="text"
                    placeholder="Lastname"
                    name="lastname"
                    onChange={handleChange}
                  />
                </p>

                <p className="half1">
                  <br />
                  <input
                    type="number"
                    placeholder="Phone*"
                    name="phone"
                    onChange={handleChange}
                    required
                  />
                </p>

                <p className="half2">
                  <br />
                  <input
                    type="email"
                    placeholder="Email*"
                    name="email"
                    onChange={handleChange}
                    required
                  />
                </p>

                <p className="full">
                  <input type="file" name="files" />
                </p>

                <p className="full">
                  <br />
                  <textarea
                    placeholder="You can write your message here"
                    name="message"
                    onChange={handleChange}
                    required
                  />
                </p>
              </div>

              <p>
                <button type="submit" className="submitbtn" disabled={disabled}>
                  Submit {showSpinner && <Spinner />}
                </button>
              </p>
            </form>
          </div>
        </ContactForm>
      </PageWrapper>
    </>
  )
}

export { ContactUs }

const CustomerService = styled.div`
  background: #4d90fe;
  padding: 3.5rem;

  h1 {
    font-size: 32px;
    font-weight: bold !important;
    color: white;
    font-family: 'Avenir Book', sans-serif;
  }
`

const ContactForm = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(72, 94, 91, 0.3);
  width: 60%;
  font-family: sofia-pro, sans-serif;

  @media (max-width: 700px) {
    width: 100%;
  }

  h1 {
    font-size: 25px;
    padding: 1.5rem;
  }

  .input {
    border: solid 1px #dbdbdb;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    padding: 15px 25px;
  }

  textarea {
    border-radius: 0;
    flex-grow: 1;
    line-height: 1.6;
    padding: 15px 25px;
  }
`

const PageWrapper = styled.div`
  @media (min-width: 700px) {
    margin-left: auto;
    margin-right: auto;
    padding-left: 4rem;
    padding-right: 4rem;
  }
`
