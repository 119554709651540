export const validateFirstName = {
  required: 'First Name is required',
  maxLength: {
    value: 30,
    message: 'First Name should have a maximum length of 30',
  },
  minLength: {
    value: 3,
    message: 'First Name should have a minimum length of 3',
  },
}
export const validateLastName = {
  required: 'Last Name is required',
  maxLength: {
    value: 30,
    message: 'Last Name should have a maximum length of 30',
  },
  minLength: {
    value: 3,
    message: 'Last Name should have a minimum length of 3',
  },
}

export const validateEmail = {
  required: 'Email is required',
}

export const validateAddress = {
  required: 'Address is required',
}

export const validatePassword = {
  required: 'Password is required',
  pattern: {
    value: /^[a-zA-Z0-9]{3,30}$/i,
    message: 'Password did not match required pattern',
  },
}

export const validatePhone = {
  required: 'Phone Number is required',
  maxLength: {
    value: 11,
    message: 'Phone Number should have a maximum length of 11',
  },
  minLength: {
    value: 10,
    message: 'Phone Number should have a minimum length of 10',
  },
}
