import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import {
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Box,
  Text,
  Button,
  FormErrorMessage,
} from '@chakra-ui/core'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { validateEmail, validatePassword } from '../utils'
import { AuthContext, LOGIN } from '../context/auth'
import { login } from '../network/auth'

const Login = () => {
  const { register, handleSubmit, errors } = useForm()
  const { dispatch } = useContext(AuthContext)
  const history = useHistory()
  const [loading, setloading] = useState(false)

  const onSubmit = async (data) => {
    setloading(true)
    try {
      data.username = data.firstname
      const res = await login(data)

      if (res.user.confirmed) {
        dispatch({ type: LOGIN, payload: res })
        history.push('/')
        toast(`Welcome back, ${res.user.firstname} ${res.user.lastname}`)
        setloading(false)
      }
    } catch (error) {
      const message = error?.response?.data?.message[0]?.messages[0]?.message || 'Login failed'
      toast(message.replace('Identifier', 'Email'))
      setloading(false)
    }
  }

  return (
    <>
      <SimpleGrid maxW="350px" mx="auto" mt={70} mb={130}>
        <Text fontSize="32px" fontFamily="black">
          Login
        </Text>
        <Box as="form" onSubmit={handleSubmit(onSubmit)} py={8}>
          <SimpleGrid>
            <FormControl py={8} isInvalid={errors.email}>
              <FormLabel htmlFor="email" fontSize="lgx1" fontFamily="book">
                Email address
              </FormLabel>
              <Input
                type="email"
                id="identifier"
                placeholder="Enter email address"
                fontSize="16px"
                name="identifier"
                p={10}
                borderColor="#ddd"
                fontFamily="book"
                ref={register(validateEmail)}
              />
              <FormErrorMessage>{errors.identifier && errors.identifier.message}</FormErrorMessage>
            </FormControl>

            <FormControl py={6} isInvalid={errors.password}>
              <FormLabel htmlFor="password" fontSize="lgx1" fontFamily="book">
                Password
              </FormLabel>
              <Input
                type="password"
                id="password"
                placeholder="Enter password"
                fontSize="16px"
                name="password"
                p={10}
                borderColor="#ddd"
                fontFamily="book"
                ref={register(validatePassword)}
              />
              <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
            </FormControl>
            <Link to="/forgotpassword">
              <Text
                color="black"
                fontSize="14px"
                fontFamily="medium"
                textAlign="right"
                py={2}
                mb={4}
              >
                Forgot password ?
              </Text>
            </Link>

            <Button
              size="md"
              height="60px"
              width="100%"
              border="2px"
              variantColor="blue"
              fontSize="18px"
              mt={16}
              type="submit"
              isLoading={loading}
            >
              Login
            </Button>
          </SimpleGrid>
        </Box>
        <Text py={2} fontSize="14px" fontFamily="light" display="flex">
          {/* eslint-disable-next-line */}
          Don't have an account?
          <Link to="/signup">
            <Text as="span" pl="1rem" color="black" fontFamily="medium">
              Create account now
            </Text>
          </Link>
        </Text>
      </SimpleGrid>
    </>
  )
}

export { Login }
