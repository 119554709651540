import axios from 'axios'
import instance from './axios'

export const getBannerData = async () => {
  const [categories, tags] = await axios.all([instance.get('/categories'), instance.get('/tags')])

  return {
    categories: categories.data.sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    }),
    tags: tags.data,
  }
}
