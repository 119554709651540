import React from 'react'
import { Box, Flex, Skeleton } from '@chakra-ui/core'

function HomeSkeleton() {
  return (
    <>
      <Box height="450px" width="100%" backgroundColor="rgba(195, 204, 215, .4)">
        <Box pt="10rem" maxW="1280px" mx="auto">
          <Skeleton mx="16" height="60px" width={['300px', '500px']} mb="1rem" />
          <Skeleton mx="16" height="20px" width="250px" mb="1rem" />
          <Skeleton mx="16" height="20px" width="200px" mb="1rem" />
          <Skeleton mx="16" height="30px" width="120px" mb="1rem" mt="3rem" />
        </Box>
      </Box>
      <Box mt="5rem">
        <Skeleton height="3rem" width="300px" mx="auto" />
        <Flex
          direction={['column', 'column', 'row']}
          maxW="950px"
          mx="auto"
          justifyContent="space-between"
        >
          <Skeleton height="138px" w={['80%', '220px']} m="10px" mx="auto" />
          <Skeleton height="138px" w={['80%', '220px']} m="10px" mx="auto" />
          <Skeleton height="138px" w={['80%', '220px']} m="10px" mx="auto" />
          <Skeleton height="138px" w={['80%', '220px']} m="10px" mx="auto" />
        </Flex>
      </Box>
      <Box mt="10rem" maxW="1280px" mx="auto" px="16">
        <Skeleton height="3rem" width="300px" m="10px" mx="auto" />
        <Flex direction={['column', 'column', 'row']} mx="auto" justifyContent="space-between">
          <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
          <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
          <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
          <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
          <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
        </Flex>
      </Box>
      <Flex
        maxW="1280px"
        mx="auto"
        d={['none', 'none', 'flex']}
        alignItems="flex-end"
        justify="space-between"
      >
        <Box mt="10rem" width="100%" mx="auto" px="16">
          <Skeleton height="3rem" width="300px" m="10px" />
          <Flex direction={['column', 'column', 'row']} mx="auto" justifyContent="space-between">
            <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
            <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
            <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
            <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
          </Flex>
          <Flex direction={['column', 'column', 'row']} mx="auto" justifyContent="space-between">
            <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
            <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
            <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
            <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
          </Flex>
        </Box>
        <Skeleton height="505px" width="220px" m="10px" />
      </Flex>
      <Flex
        maxW="1280px"
        mx="auto"
        d={['none', 'none', 'flex']}
        alignItems="flex-end"
        justify="space-between"
        pl="16"
      >
        <Skeleton height="242px" width="260px" m="10px" />
        <Box mt="10rem" mx="auto">
          <Flex direction={['column', 'column', 'row']} mx="auto">
            <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
            <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
            <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
            <Skeleton h="242px" w={['80%', '220px']} m="10px" mx="auto" />
          </Flex>
        </Box>
      </Flex>
      <Skeleton d={['none', 'none', 'block']} height="246px" mt="5rem" />
      <Skeleton
        d={['none', 'none', 'block']}
        height="558px"
        w="95%"
        maxW="1135px"
        mx="auto"
        my="5rem"
      />
      {/* <Flex d={['none', 'none', 'block']} direction={['column', 'column', 'row']} w="100%">
        <Skeleton h="300px" width="25%" />
        <Skeleton h="300px" width="25%" />
        <Skeleton h="300px" width="25%" />
        <Skeleton h="300px" width="25%" />
        <Skeleton h="300px" width="25%" />
      </Flex> */}
    </>
  )
}

export { HomeSkeleton }
