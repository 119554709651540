import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { usePaystackPayment } from 'react-paystack'
import { useForm } from 'react-hook-form'
import { Link, Route, useHistory } from 'react-router-dom'
import {
  Icon,
  Flex,
  Button,
  Text,
  Box,
  Heading,
  List,
  ListItem,
  FormControl,
  FormLabel,
  Textarea,
  Input,
  SimpleGrid,
  Link as CLink,
} from '@chakra-ui/core'
import { toast } from 'react-toastify'

import { PageWrapper } from '../components/layout/PageWrapper'
import { Feel } from '../components/Feel'
import { CartItem, FormError, Step } from '../components/common'

import { CartContext, EMPTY_CART } from '../context/cart'
import { AuthContext } from '../context/auth'

import { states, validateEmail, roundUp } from '../utils'

import { saveOrder } from '../network/checkout'

import { sendOrderEmail } from '../network/order'

function CartItems() {
  const { cartState } = useContext(CartContext)

  return (
    <>
      {cartState.map((item) => (
        <CartItem item={item} key={item.cartId} />
      ))}
    </>
  )
}

const StyledSelect = styled.select`
  width: 100%;
  transition: all 0.2s;
  outline: none;
  appearance: none;
  font-size: 1.4rem;
  border-radius: 0.25rem;
  border: 1px solid #f4f4f4;
  background-color: #fff;
  font-family: 'Avenir Book', sans-serif;
  padding: 1.2rem;
  margin-top: 1rem;

  &:focus {
    border: 2px solid #fd3b90 !important;
  }
`

const PaystackHook = ({ user, total, customerInfo, deliveryInfo, items }) => {
  const { authState } = useContext(AuthContext)
  const { dispatch } = useContext(CartContext)
  const [paymentTrue, setPayment] = useState(false)
  const history = useHistory()

  let config = {
    amount: Math.ceil(total * 100),
    publicKey: process.env.REACT_APP_PAYSTACK_KEY_TEST,
  }

  if (authState !== null) {
    const userObj = {
      email: authState.user.email,
      firstname: authState.user.firstname,
      lastname: authState.user.lastname,
    }
    config = Object.assign(config, userObj)
  }

  if (authState === null) {
    const userObj = {
      email: customerInfo.email,
      firstname: customerInfo.firstname,
      lastname: customerInfo.lastname,
    }

    config = Object.assign(config, userObj)
  }

  const initializePayment = usePaystackPayment(config)

  useEffect(() => {
    const onSuccess = (reference) => {
      saveOrder(user, total, customerInfo, deliveryInfo, items, reference)
        .then(async () => {
          await sendOrderEmail(user, total, customerInfo, deliveryInfo, items)
          setPayment(true)
          toast('Order placed successfully')
          dispatch({ type: EMPTY_CART })
          if (authState !== null) {
            history.push('/profile')
          } else history.push('/')
        })
        .catch(() => {
          toast('Error saving order')
        })
    }

    const onClose = () => {
      toast('Payment Cancelled')
    }

    if (!paymentTrue) {
      initializePayment(onSuccess, onClose)
    }
  }, [
    user,
    total,
    customerInfo,
    deliveryInfo,
    items,
    history,
    dispatch,
    paymentTrue,
    initializePayment,
    authState,
  ])

  return <div />
}

function DeliveryDetails() {
  const { cartState, cartSummary } = useContext(CartContext)
  const { authState } = useContext(AuthContext)
  const [orderInfo, setOrderInfo] = useState(null)

  const { handleSubmit, register, errors } = useForm()

  const onSubmit = async (checkoutData) => {
    const user = authState?.user?.id

    const total = Number(cartSummary.subtotal) + Number(cartSummary.vat)

    const deliveryInfo = {
      country: checkoutData.country,
      state: checkoutData.state,
      region: checkoutData.region,
      street: checkoutData.street,
    }

    const customerInfo = {
      firstname: checkoutData.firstname,
      lastname: checkoutData.lastname,
      email: checkoutData.email,
      phone: checkoutData.phone,
    }

    const items = cartState.map((cartItem) => {
      return {
        price: cartItem.price,
        choices: '',
        product: cartItem.id,
        shipping: {
          name: cartItem.shipping.name,
          image: '',
          price: cartItem.shipping.price,
        },
        quantity: {
          amount: cartItem.quantity.amount,
          price: cartItem.quantity.price,
        },
        files: JSON.stringify(cartItem.files),
        productDetails: cartItem.productDetails,
      }
    })

    setOrderInfo({ user, total, deliveryInfo, customerInfo, items })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p="3.2rem" mt="5rem" border="1px solid #F4F4F4" boxShadow="0px 3px 6px #00000010">
          <Heading mb="3.8rem" w="full" fontFamily="heavy" fontSize="2.2rem">
            Customer Information
          </Heading>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={10}>
            <FormControl>
              <FormLabel fontFamily="book" fontSize="1.4rem" htmlFor="country">
                Firstname <FormError>{errors.firstname && errors.firstname.message}</FormError>
              </FormLabel>
              <Input
                focusBorderColor="#177BE2"
                mt="0.9rem"
                borderColor="#F4F4F4"
                border="1px solid #F4F4F4"
                fontFamily="book"
                fontSize="1.4rem"
                p={8}
                name="firstname"
                ref={register({ required: 'Firstname is required' })}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontFamily="book" fontSize="1.4rem" htmlFor="state">
                Lastname <FormError>{errors.lastname && errors.lastname.message}</FormError>
              </FormLabel>
              <Input
                focusBorderColor="#177BE2"
                mt="0.9rem"
                borderColor="#F4F4F4"
                border="1px solid #F4F4F4"
                fontFamily="book"
                fontSize="1.4rem"
                p={8}
                name="lastname"
                ref={register({ required: 'Lastname is required' })}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontFamily="book" fontSize="1.4rem" htmlFor="state">
                Email <FormError>{errors.email && errors.email.message}</FormError>
              </FormLabel>
              <Input
                focusBorderColor="#177BE2"
                mt="0.9rem"
                borderColor="#F4F4F4"
                border="1px solid #F4F4F4"
                fontFamily="book"
                fontSize="1.4rem"
                p={8}
                name="email"
                ref={register(validateEmail)}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontFamily="book" fontSize="1.4rem" htmlFor="state">
                Phone Number <FormError>{errors.phone && errors.phone.message}</FormError>
              </FormLabel>
              <Input
                focusBorderColor="#177BE2"
                mt="0.9rem"
                borderColor="#F4F4F4"
                border="1px solid #F4F4F4"
                fontFamily="book"
                fontSize="1.4rem"
                p={8}
                name="phone"
                ref={register({ required: 'Phone number is required' })}
              />
            </FormControl>
          </SimpleGrid>
        </Box>
        <Box p="3.2rem" mt="5rem" border="1px solid #F4F4F4" boxShadow="0px 3px 6px #00000010">
          <Heading mb="3.8rem" w="full" fontFamily="heavy" fontSize="2.2rem">
            Delivery Address
          </Heading>
          <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={10}>
            <FormControl>
              <FormLabel fontFamily="book" fontSize="1.4rem" htmlFor="country">
                Country {errors.country && errors.country.message}
              </FormLabel>
              <StyledSelect
                mt="0.9rem"
                borderColor="#F4F4F4"
                border="1px solid #F4F4F4"
                fontFamily="book"
                fontSize="1.4rem"
                name="country"
                ref={register({ required: true })}
                p={8}
              >
                <option value="ng">Nigeria</option>
              </StyledSelect>
            </FormControl>
            <FormControl>
              <FormLabel fontFamily="book" fontSize="1.4rem" htmlFor="state">
                State {errors.state && errors.state.message}
              </FormLabel>
              <StyledSelect
                display="block"
                mt="0.9rem"
                borderColor="#F4F4F4"
                border="1px solid #F4F4F4"
                fontFamily="book"
                fontSize="1.4rem"
                name="state"
                ref={register({ required: true })}
                p={8}
              >
                {states.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </StyledSelect>
            </FormControl>
            <FormControl>
              <FormLabel fontFamily="book" fontSize="1.4rem" htmlFor="region">
                Region <FormError>{errors.region && errors.region.message}</FormError>
              </FormLabel>
              <Input
                focusBorderColor="#177BE2"
                mt="0.9rem"
                borderColor="#F4F4F4"
                border="1px solid #F4F4F4"
                fontFamily="book"
                fontSize="1.4rem"
                p={8}
                name="region"
                ref={register({ required: 'Region is required' })}
              />
            </FormControl>
          </SimpleGrid>
          <FormControl mt="1.6rem">
            <FormLabel fontFamily="book" fontSize="1.4rem" htmlFor="region">
              Street <FormError>{errors.street && errors.street.message}</FormError>
            </FormLabel>
            <Textarea
              focusBorderColor="#177BE2"
              mt="0.9rem"
              borderColor="#F4F4F4"
              border="1px solid #F4F4F4"
              fontFamily="book"
              fontSize="1.4rem"
              p={8}
              resize="none"
              name="street"
              ref={register({ required: 'Street is required' })}
            />
          </FormControl>
          <Flex
            mt="3rem"
            align="center"
            justifyContent="space-between"
            direction={['column', 'column', 'row']}
          >
            <Button
              border="none"
              py="2rem"
              fontFamily="book"
              variantColor="blue"
              bg="#177BE2"
              w="full"
              borderRadius="0"
              maxW="244px"
              fontSize="1.3rem"
              type="submit"
            >
              Pay Now
            </Button>
            <Link to="/checkout">
              <Text
                color="brand.pink"
                fontFamily="medium"
                fontSize="1.6rem"
                mt={['3rem', '3rem', '0']}
              >
                Go Back
              </Text>
            </Link>
          </Flex>
        </Box>
      </form>
      {orderInfo && <PaystackHook {...orderInfo} />}
    </>
  )
}

const Checkout = ({ match, location }) => {
  const { cartSummary } = useContext(CartContext)

  const history = useHistory()

  return (
    <>
      <Box bg="#eef5fd">
        <Box maxW="1280px" mx="auto" pl={4}>
          <Flex
            p="1rem"
            ml="2rem"
            justify="flex-start"
            alignItems="center"
            h="42px"
            fontSize="1.2rem"
          >
            <Box
              background="#E3EAF1"
              border="1px solid #EDF5FD"
              pl="8px"
              pr="10px"
              py="4.7px"
              mr="2rem"
              style={{ clipPath: 'polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)' }}
            >
              <Text fontFamily="light">
                <CLink as={Link} color="#808080" to="/">
                  Home
                </CLink>
              </Text>
            </Box>
            <Text color="#808080" mr="2.5rem" fontFamily="light">
              Cart
            </Text>
          </Flex>
        </Box>
      </Box>
      <PageWrapper
        mb="10rem"
        py="2rem"
        d={['block', 'block', 'flex']}
        justifyContent="space-between"
      >
        <Box flex="1" mr={['0', '0', '2rem']}>
          <Flex align="center">
            <Button
              onClick={() => history.goBack()}
              mr="10rem"
              border="none"
              bg="white"
              boxShadow="0px 3px 6px #00000010"
            >
              <Icon name="triangle-down" transform="rotate(90deg)" mr="1rem" />
              Go Back
            </Button>
            <Flex w="615px" justify="space-between" align="center">
              <Step color="#FD3B90" title="Shopping Cart" />
              <Box
                w="1px"
                flex="1"
                h="1px"
                background={location.pathname.includes('delivery') ? '#fd3b90' : '#808080'}
                opacity="0.35"
              />
              <Step
                color={location.pathname.includes('delivery') ? '#fd3b90' : '#808080'}
                title="Login"
              />
              <Box
                w="1px"
                flex="1"
                h="1px"
                background={location.pathname.includes('delivery') ? '#fd3b90' : '#808080'}
                opacity="0.35"
              />
              <Step
                color={location.pathname.includes('delivery') ? '#fd3b90' : '#808080'}
                title="Delivery Address"
              />
              <Box w="1px" flex="1" h="1px" background="#808080" opacity="0.35" />
              <Step title="Payment" />
            </Flex>
          </Flex>
          <Route exact path={`${match.url}`} component={CartItems} />
          <Route exact path={`${match.url}/delivery`} component={DeliveryDetails} />
        </Box>
        <Box
          pos="sticky"
          top="0"
          w={['100%', '100%', '280px']}
          h="400px"
          boxShadow="0px 3px 6px #00000010;"
        >
          <Heading mt="2rem" px="2rem" py="1rem" fontSize="1.7rem" fontFamily="heavy">
            Overview
          </Heading>
          <List>
            <ListItem borderBottom="1px solid #F4F4F4" px="2rem" py="1rem">
              <Flex justify="space-between">
                <Heading fontFamily="medium" fontSize="1.2rem">
                  Basket
                </Heading>
                <Heading fontFamily="light" fontSize="1.2rem">
                  ₦{roundUp(Number(cartSummary.subtotal))}
                </Heading>
              </Flex>
            </ListItem>
            <ListItem borderBottom="1px solid #F4F4F4" px="2rem" py="1rem">
              <Flex justify="space-between">
                <Heading fontFamily="medium" fontSize="1.2rem">
                  Subtotal
                </Heading>
                <Heading fontFamily="light" fontSize="1.2rem">
                  ₦{roundUp(Number(cartSummary.subtotal))}
                </Heading>
              </Flex>
              <Flex justify="space-between" mt="2">
                <Text color="#A0A0A0" fontFamily="light" fontSize="1.2rem">
                  VAT
                </Text>
                <Text color="brand.pink" fontSize="1.2rem" fontFamily="medium">
                  ₦{roundUp(Number(cartSummary.vat))}
                </Text>
              </Flex>
            </ListItem>
            <ListItem
              d="flex"
              justifyContent="space-between"
              borderBottom="1px solid #F4F4F4"
              px="2rem"
              py="1rem"
            >
              <Heading fontFamily="medium" fontSize="1.2rem">
                Total
              </Heading>
              <Heading fontFamily="heavy" fontSize="1.2rem">
                ₦{roundUp(Number(cartSummary.subtotal) + Number(cartSummary.vat))}
              </Heading>
            </ListItem>
          </List>
          <Text mt="1.3rem" px="2rem" fontFamily="light" fontSize="1.2rem" lineHeight="1.5">
            Warner Print is the most affordable print supplier in your local area!
          </Text>
          {!location.pathname.includes('delivery') && (
            <Box mx="2rem" mt="2.2rem">
              <Link to={`${match.url}/delivery`}>
                <Button
                  cursor="pointer"
                  w="100%"
                  fontSize="1.2rem"
                  px="2.2rem"
                  borderRadius="0"
                  height="36px"
                  variantColor="blue"
                  fontWeight="normal"
                  border="none"
                  background="brand.blue"
                  mb="10rem"
                  d="flex"
                  alignItems="center"
                  backgroundColor="#177BE2"
                >
                  Continue
                </Button>
              </Link>
            </Box>
          )}
        </Box>
      </PageWrapper>
      <Feel />
    </>
  )
}

export { Checkout }
