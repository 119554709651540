import React from 'react'

import { Box } from '@chakra-ui/core'

import { PageWrapper } from './PageWrapper'
import { Product } from '../common/Product'

function GridMenu({ setVisibility, products }) {
  return (
    <PageWrapper
      position="relative"
      onMouseEnter={() => setVisibility(true)}
      onMouseLeave={() => setVisibility(false)}
    >
      <Box
        minH="500px"
        w="100%"
        shadow="0px 3px 2px #00000021"
        position="absolute"
        top="1px"
        zIndex="100"
        background="#fff"
      >
        <Box d="flex" flexWrap="wrap" padding="2.5rem">
          {products.map((item) => (
            <Box key={item.id} w="300px" flexShrink="0">
              <Product details item={item} boxShadow="none" maxW="242px" detailsLen={100} />
            </Box>
          ))}
        </Box>
      </Box>
    </PageWrapper>
  )
}

export { GridMenu }
