import React, { useState } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { Box, Flex, SimpleGrid, Image } from '@chakra-ui/core'
import { PageWrapper } from '../layout'
import { SectionTitle, Product } from '../common'

import seller from '../../assets/images/seller.png'
import left from '../../assets/images/icons/left-arrow.svg'
import right from '../../assets/images/icons/right-arrow.svg'

function BestSellers({ topSelling, tags }) {
  const [activeTag, setActiveTag] = useState(tags[0])

  const projectsVariants = {
    hidden: {
      opacity: 0,
    },
    visible: { opacity: 1 },
  }

  const prod = {
    hidden: {
      y: 30,
      x: 30,
      opacity: 0,
    },
    visible: {
      y: 0,
      x: 0,
      opacity: 1,
    },
  }

  const cardSlider = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderArrow top="50%" position="right" src={right} alt="Right arrow" />,
    prevArrow: <SliderArrow top="50%" src={left} alt="Left arrow" />,
  }

  const categoriesSlider = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: (
      <SliderArrow top="2.8rem !important" position="right" src={right} alt="Right arrow" />
    ),
    prevArrow: <SliderArrow top="2.8rem !important" src={left} alt="Left arrow" />,
  }

  return (
    <PageWrapper mt="6rem">
      <SectionTitle title={topSelling.title} />

      <Flex d={['none', 'none', 'flex']} justify="space-between" overflow="hidden">
        <SimpleGrid w="100%" mt="12" minChildWidth="219px" spacing={10}>
          {topSelling.products.map((item) => (
            <Product key={item.id} item={item} />
          ))}
        </SimpleGrid>
        <Image d={['none', 'none', 'none', 'flex']} ml="6" w="220px" maxH="550px" src={seller} />
      </Flex>
      {/* Mobile View */}
      <Box d={['block', 'block', 'none']} py="4rem">
        <Slider {...cardSlider}>
          {topSelling.products.map((item) => (
            <Product key={item.id} item={item} />
          ))}
        </Slider>
      </Box>

      {/* Categories */}
      <Flex
        flex="1"
        d={['none', 'none', 'flex']}
        justify="space-between"
        alignItems="flex-start"
        mt="12"
      >
        <Box mr="10" mt="1rem" w="200px" flexShrink={0}>
          {tags.map((tag, i) => (
            <BannerMenuBox
              className={tag.id === activeTag.id ? 'active' : ''}
              key={tag.id}
              onClick={() => setActiveTag(tags[i])}
            >
              {tag.name}
            </BannerMenuBox>
          ))}
        </Box>

        <motion.div
          style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={projectsVariants}
        >
          {activeTag.products.slice(0, 8).map((item, i) => (
            <Product
              variants={prod}
              transition={{ duration: 0.3, delay: i / 2 }}
              key={item.id}
              item={item}
              mx="1rem"
              mb="2rem"
            />
          ))}
        </motion.div>
      </Flex>

      <Box d={['block', 'block', 'none']}>
        <Box py="4rem" maxW="90%" mx="auto">
          <Slider {...categoriesSlider}>
            {tags.map((tag, i) => (
              <BannerMenuBox
                className={tag.id === activeTag.id ? 'active' : ''}
                key={tag.id}
                onClick={() => setActiveTag(tags[i])}
              >
                {tag.name}
              </BannerMenuBox>
            ))}
          </Slider>
        </Box>

        <Flex w="100%" wrap="wrap">
          {activeTag.products.map((item) => (
            <Product key={item.id} item={item} mx="1rem" mb="2rem" />
          ))}
        </Flex>
      </Box>
    </PageWrapper>
  )
}

export { BestSellers }

const BannerMenuBox = styled(Box)`
  cursor: pointer;
  font-family: 'Avenir Light';
  font-size: 1.1rem;
  padding: 2rem;
  width: 100%;
  border: 1px solid #e0e0e0;
  margin-bottom: 1.2rem;

  &.active {
    border: none;
    background: rgba(23, 123, 226, 0.1);
    border-right: 3px solid #3182ce;
    color: #177be2;
  }

  &:hover {
    border: none;
    background: rgba(23, 123, 226, 0.1);
    border-right: 3px solid #3182ce;
    color: #177be2;
  }
`

const SliderArrow = styled.img`
  width: 30px;
  height: 50px;
  z-index: 2;
  top: ${({ top }) => top || '2rem !important'};
  ${({ position }) => (position === 'right' ? 'right: -3rem' : 'left: -3rem')}
`
