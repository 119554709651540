import React from 'react'
import { Box, Skeleton } from '@chakra-ui/core'
import { PageWrapper } from '../layout'

function MenuSkeleton() {
  return (
    <Box w="100%" py="1rem">
      <PageWrapper d="flex">
        <Skeleton mx="2rem" w="150px" h="40px" />
        <Skeleton mx="2rem" w="150px" h="40px" />
        <Skeleton mx="2rem" w="150px" h="40px" />
        <Skeleton mx="2rem" w="150px" h="40px" />
        <Skeleton mx="2rem" w="150px" h="40px" />
      </PageWrapper>
    </Box>
  )
}

export { MenuSkeleton }
