import React, { useContext, useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'

import {
  Image,
  Flex,
  Button,
  Text,
  Divider,
  Box,
  PseudoBox,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  Spinner,
} from '@chakra-ui/core'
import { toast } from 'react-toastify'

import styled from 'styled-components'

import { PageWrapper } from './PageWrapper'
import { Megamenu } from './Megamenu'
import { GridMenu } from './GridMenu'
import MobileMenu from './MobileMenu'
// eslint-disable-next-line import/no-cycle
import { MenuSkeleton } from '../skeletons'

import { getBannerData } from '../../network/megamenu'

import { AuthContext, LOGOUT } from '../../context/auth'

import logo from '../../assets/images/logo.png'
import cart from '../../assets/images/icons/cart-white.png'
import ham from '../../assets/images/icons/menu.svg'
import { CartContext } from '../../context/cart'

import { search } from '../../network/search'
// import user from '../../assets/images/icons/user.svg'

const HeaderLinks = (props) => {
  const { children } = props
  return (
    <Text
      as={Link}
      to="/"
      fontFamily="Avenir Light"
      mx="5"
      fontSize="1.2rem"
      color="#808080"
      {...props}
    >
      {children}
    </Text>
  )
}

function Header() {
  const [showMegamenu, setShowMegamenu] = useState(false)
  const [showGridmenu, setShowGridmenu] = useState(false)
  const [activeTag] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [showSearchResult, setShowSearchResult] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const { authState, dispatch } = useContext(AuthContext)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (showSearchResult) setShowSearchResult(false)
    setSearchValue('')
    setSearchResult([])
  }, [location.pathname])

  const { cartState } = useContext(CartContext)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  const { isLoading, data, isError } = useQuery('banner', getBannerData)

  const logout = () => {
    dispatch({ type: LOGOUT, payload: null })
    history.push('/')
    toast(`Logged out`)
  }

  if (isError) {
    return <p>Error Occured</p>
  }

  async function handleSearch(event) {
    const { value } = event.target

    setSearchValue(value)

    if (value !== '' && value.length > 2) {
      setShowSpinner(true)
      setSearchResult([])
      setShowSearchResult(true)
    }
    if (searchValue.length > 2) {
      const products = await search(value)
      setShowSpinner(false)
      setSearchResult(products.data)
    }

    if (searchValue.length < 2 && showSearchResult) {
      setShowSearchResult(false)
      setSearchResult([])
    }
  }

  function handleMouseLeave() {
    setTimeout(() => {
      setShowSearchResult(false)
    }, 3000)
  }

  return (
    <>
      {authState?.user?.verified === false && (
        <Box
          background="black"
          textAlign="center"
          color="white"
          fontFamily="Avenir Light"
          fontSize="1.3rem"
          justifyContent="space-between"
          py="8"
          px="16"
          mx="auto"
        >
          Your Email has not been verified, Pls check your email account for the verification code.
          {''}{' '}
          <a href="/verify" style={{ color: 'white', textDecoration: 'underline' }}>
            Click here to verify your account now.
          </a>
        </Box>
      )}

      <Box borderBottom="1px solid #d8d8d8" as="header">
        <Flex
          align="center"
          justifyContent="space-between"
          py="8"
          px="16"
          maxW="1280px"
          mx="auto"
          pt="1.5rem"
          pb="1.5rem"
        >
          <Flex>
            <Link to="/">
              <Image h="55px" src={logo} alt="" />
            </Link>
            <InputGroup d={['none', 'none', 'none', 'block']} ml="8" mt="auto" width="231px">
              <Input
                height="38px"
                borderColor="#D8D8D8"
                px="8"
                fontFamily="Avenir Light"
                fontSize="13px"
                placeholder="Find a product…"
                borderRadius="0"
                color="#808080"
                value={searchValue}
                onChange={handleSearch}
              />
              <InputRightElement px="10" height="38px" children={<Icon name="search" />} />
              {showSearchResult && (
                <SearchData
                  onMouseLeave={handleMouseLeave}
                  data={searchResult}
                  showSpinner={showSpinner}
                />
              )}
            </InputGroup>
          </Flex>

          <Image
            d={['block', 'block', 'block', 'none']}
            ref={btnRef}
            onClick={onOpen}
            w="3.5rem"
            src={ham}
          />

          <Flex d={['none', 'none', 'none', 'flex']}>
            <Flex align="center">
              {/* <HeaderLinks color="brand.blue">Cost Calculator</HeaderLinks>
              <Divider borderColor="#808080" height="12px" orientation="vertical" />
              <HeaderLinks>Request quote</HeaderLinks>
              <Divider borderColor="#808080" height="12px" orientation="vertical" />
              <HeaderLinks>Warnerprint pro</HeaderLinks> */}
              <Divider borderColor="#808080" height="12px" orientation="vertical" />
              <HeaderLinks mr="10">09123301206</HeaderLinks>
            </Flex>
            {authState?.jwt ? (
              <Flex alignItems="center">
                <Menu>
                  <MenuButton
                    as={Button}
                    fontSize="1.2rem"
                    height="38px"
                    px="10"
                    py="5"
                    borderRadius="0"
                    variantColor="blue"
                    fontWeight="normal"
                    border="none"
                    mr="4"
                    background="brand.blue"
                  >
                    My Account
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      pt="1rem"
                      ml="0"
                      as={HeaderLinks}
                      to="/profile"
                      _hover={{ background: 'transparent' }}
                      display="block"
                    >
                      Profile
                    </MenuItem>
                    <MenuItem ml="0" as={HeaderLinks} onClick={logout}>
                      Logout
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            ) : (
              <Link to="/login">
                <Button
                  fontSize="1.2rem"
                  height="38px"
                  px="10"
                  py="5"
                  borderRadius="0"
                  variantColor="blue"
                  fontWeight="normal"
                  border="none"
                  mr="4"
                  background="brand.blue"
                >
                  Login
                </Button>
              </Link>
            )}
            <Link to="/checkout">
              <Button
                fontSize="1.2rem"
                height="38px"
                px="10"
                py="5"
                borderRadius="0"
                variantColor="pink"
                fontWeight="normal"
                border="none"
                bg="brand.pink"
              >
                <img src={cart} alt="cart" />{' '}
                <span style={{ marginLeft: '5px' }}>
                  My Cart{' '}
                  <span
                    style={{
                      background: '#ffffff',
                      padding: '3px',
                      color: '#000000',
                      borderRadius: '2px',
                      marginLeft: '6px',
                    }}
                  >
                    {cartState.length}
                  </span>
                </span>
              </Button>
            </Link>
          </Flex>
        </Flex>
        <Box m="1rem" d={['block', 'none', 'none', 'none']}>
          <InputGroup mt="auto" d="block" width="100%">
            <Input
              height="38px"
              borderColor="#D8D8D8"
              px="8"
              fontFamily="Avenir Light"
              fontSize="13px"
              placeholder="Find a product…"
              borderRadius="0"
              color="#808080"
              value={searchValue}
              onChange={handleSearch}
            />
            <InputRightElement px="10" height="38px" children={<Icon name="search" />} />
            {showSearchResult && (
              <SearchData
                style={{ position: 'relative !important' }}
                onMouseLeave={handleMouseLeave}
                data={searchResult}
                showSpinner={showSpinner}
              />
            )}
          </InputGroup>
        </Box>
      </Box>

      <Box borderBottom="1px solid #d8d8d8" w="100%" as="nav">
        {isLoading ? (
          <MenuSkeleton />
        ) : (
          <PageWrapper d={['none', 'none', 'none', 'flex']}>
            <NavText setShowMegamenu={setShowMegamenu} mr="8">
              All products
            </NavText>
            <Divider orientation="vertical" my="8" mr="8" ml="0" borderColor="#A7A7A7" />
            {data.tags.map((item) => (
              <Link key={item.id} to={`/products/tag/${item.id}`}>
                <NavText item={item} />
              </Link>
            ))}
          </PageWrapper>
        )}
        {showMegamenu && <Megamenu categories={data.categories} setVisibility={setShowMegamenu} />}
        {showGridmenu && <GridMenu products={activeTag.products} setVisibility={setShowGridmenu} />}
      </Box>

      <MobileMenu categories={data?.categories} isOpen={isOpen} onClose={onClose} btnRef={btnRef} />
    </>
  )
}

export { Header }

const NavText = (props) => {
  const { children, item, setShowMegamenu, setActiveTag } = props

  function handleMouseEnter() {
    if (setShowMegamenu) setShowMegamenu(true)
    if (setActiveTag) setActiveTag(item)
  }

  function handleMouseExit() {
    if (setShowMegamenu) setShowMegamenu(false)
  }

  return (
    <PseudoBox
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseExit}
      color="#A7A7A7"
      mr="16"
      fontFamily="Avenir Light"
      cursor="pointer"
      py="8"
      {...props}
      _hover={{ color: '#177BE2' }}
    >
      {item?.name || children}
    </PseudoBox>
  )
}

function SearchData({ data, showSpinner }) {
  return (
    <SearchContainer>
      {showSpinner && (
        <Box textAlign="center" p="2rem">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Box>
      )}
      {!showSpinner && data.length === 0 && (
        <li>
          <span>Product not found</span>
          <small>Search for another product</small>
        </li>
      )}
      {data.length > 0 &&
        data?.map((item) => (
          <Link key={item.id} to={`/products/${item.id}/order`}>
            <li>
              <Flex>
                <div>
                  <img src={item.image} />
                </div>
                <div>
                  <span>{item.name}</span>
                  <small>{item.category}</small>
                </div>
              </Flex>
            </li>
          </Link>
        ))}
    </SearchContainer>
  )
}

const SearchContainer = styled.ul`
  position: absolute;
  background: #fff;
  list-style: none;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  margin-top: 0.5rem !important;
  width: 100% !important;
  z-index: 9999999;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  font-family: 'Avenir Medium', sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  max-height: 300px;
  overflow-y: scroll;

  li {
    cursor: pointer;
    position: relative;
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
    width: 100% !important;
    border-top: solid 1px #ccc;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      margin-right: 10px;
    }

    span {
      display: block;
      font-size: 13px;
      font-weight: bold;

      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: -webkit-box !important;
      -webkit-line-clamp: 1 !important;
      -webkit-box-orient: vertical;
      --x-height-multiplier: 0.342 !important;
      --baseline-multiplier: 0.22 !important;
      transform: translateY(0.96px);
    }

    small {
      margin-bottom: auto;
      font-size: 11px;
    }
  }
`
