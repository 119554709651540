import React, { useState, createContext, useReducer, useEffect } from 'react'
import { cartReducer } from './reducer'

const CartContext = createContext()

const CartContextProvider = ({ children }) => {
  let initialState = localStorage.getItem('cart')

  if (initialState) {
    initialState = JSON.parse(initialState)
  } else {
    initialState = []
  }

  const [cartState, dispatch] = useReducer(cartReducer, initialState)
  const [cartSummary, setSummary] = useState({
    subtotal: 0,
    vat: 0,
  })

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cartState))

    const sum = cartState.reduce((a, b) => a + (b.price || 0), 0)

    setSummary({
      subtotal: sum,
      vat: (sum * 0.075).toFixed(2),
    })
  }, [cartState])

  return (
    <CartContext.Provider value={{ cartState, cartSummary, dispatch }}>
      {children}
    </CartContext.Provider>
  )
}

export { CartContext, CartContextProvider }
