/* eslint-disable max-len */
import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Box, Text, Image } from '@chakra-ui/core'

import corner from '../../assets/images/icons/corner.svg'
import product from '../../assets/images/product/1.png'
import { truncateString } from '../../utils'

const ProductCard = styled(motion.div)`
  width: 100%;
  max-width: 100%;
  min-height: 250px;
  box-shadow: 0px 3px 6px #00000010;
  margin: 0;

  @media (min-width: 480px) {
    max-width: 220px;
    min-height: 220px;
    margin: 1rem;
  }
`

function Product(props) {
  const { item, details, detailsLen } = props

  return (
    <ProductCard {...props}>
      <Link style={{ color: '#2d2927' }} to={`/products/${item.id}/order`}>
        <Image
          objectFit="cover"
          objectPosition="center"
          width="100%"
          h="50%"
          maxH="119px"
          src={item.images[0]?.url || product}
        />
        <Box p="2rem">
          <Text fontFamily="Avenir Heavy" fontSize="1.5rem">
            {item.name}
          </Text>
          <Text lineHeight="2" fontSize="1.2rem" fontFamily="Avenir Light">
            Starting from
            <Text mx="2" lineHeight="2" as="span" fontFamily="Avenir Heavy">
              ₦{item.quantity[0]?.price}
            </Text>
            / {item.quantity[0]?.amount}
          </Text>
          {details && (
            <Text
              my="1rem"
              fontFamily="Avenir Light"
              fontSize="1.2rem"
              color="#2D2927"
              lineHeight="2.2rem"
            >
              {truncateString(item.description, detailsLen || 150)}
            </Text>
          )}

          <Text
            d="flex"
            alignItems="center"
            mt="1"
            fontSize="1.1rem"
            color="brand.blue"
            fontFamily="Avenir Heavy"
          >
            Order Now
            <Box
              as="span"
              px="0.8rem"
              py="0.6rem"
              background="#edf2f6"
              height="2rem"
              borderRadius="full"
              minW="2rem"
              ml="2"
            >
              <Image size="9px" src={corner} />
            </Box>
          </Text>
        </Box>
      </Link>
    </ProductCard>
  )
}

export { Product }
