import React from 'react'
import styled from 'styled-components'

import bg from '../../assets/images/loader.png'

function Loader() {
  return (
    <Wrapper bg={bg}>
      <div className="sp sp-slices" />
    </Wrapper>
  )
}

export { Loader }

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
  }

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .sp {
    width: 32px;
    height: 32px;
    clear: both;
    margin: 20px auto;
  }

  .sp.sp-slices {
    border-radius: 50%;
    border-top: 16px rgba(0, 0, 0, 0.75) solid;
    border-left: 16px rgba(0, 0, 0, 0.25) solid;
    border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
    border-right: 16px rgba(0, 0, 0, 0.25) solid;
    -webkit-animation: spSlices 1s infinite linear;
    animation: spSlices 1s infinite linear;
  }

  @-webkit-keyframes spSlices {
    0% {
      border-top: 16px rgba(0, 0, 0, 0.75) solid;
      border-right: 16px rgba(0, 0, 0, 0.25) solid;
      border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
      border-left: 16px rgba(0, 0, 0, 0.25) solid;
    }
    25% {
      border-top: 16px rgba(0, 0, 0, 0.25) solid;
      border-right: 16px rgba(0, 0, 0, 0.75) solid;
      border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
      border-left: 16px rgba(0, 0, 0, 0.25) solid;
    }
    50% {
      border-top: 16px rgba(0, 0, 0, 0.25) solid;
      border-right: 16px rgba(0, 0, 0, 0.25) solid;
      border-bottom: 16px rgba(0, 0, 0, 0.75) solid;
      border-left: 16px rgba(0, 0, 0, 0.25) solid;
    }
    75% {
      border-top: 16px rgba(0, 0, 0, 0.25) solid;
      border-right: 16px rgba(0, 0, 0, 0.25) solid;
      border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
      border-left: 16px rgba(0, 0, 0, 0.75) solid;
    }
    100% {
      border-top: 16px rgba(0, 0, 0, 0.75) solid;
      border-right: 16px rgba(0, 0, 0, 0.25) solid;
      border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
      border-left: 16px rgba(0, 0, 0, 0.25) solid;
    }
  }
  @keyframes spSlices {
    0% {
      border-top: 16px rgba(0, 0, 0, 0.75) solid;
      border-right: 16px rgba(0, 0, 0, 0.25) solid;
      border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
      border-left: 16px rgba(0, 0, 0, 0.25) solid;
    }
    25% {
      border-top: 16px rgba(0, 0, 0, 0.25) solid;
      border-right: 16px rgba(0, 0, 0, 0.75) solid;
      border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
      border-left: 16px rgba(0, 0, 0, 0.25) solid;
    }
    50% {
      border-top: 16px rgba(0, 0, 0, 0.25) solid;
      border-right: 16px rgba(0, 0, 0, 0.25) solid;
      border-bottom: 16px rgba(0, 0, 0, 0.75) solid;
      border-left: 16px rgba(0, 0, 0, 0.25) solid;
    }
    75% {
      border-top: 16px rgba(0, 0, 0, 0.25) solid;
      border-right: 16px rgba(0, 0, 0, 0.25) solid;
      border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
      border-left: 16px rgba(0, 0, 0, 0.75) solid;
    }
    100% {
      border-top: 16px rgba(0, 0, 0, 0.75) solid;
      border-right: 16px rgba(0, 0, 0, 0.25) solid;
      border-bottom: 16px rgba(0, 0, 0, 0.25) solid;
      border-left: 16px rgba(0, 0, 0, 0.25) solid;
    }
  }
`
