import React, { useContext, useState } from 'react'
import { FormControl, FormLabel, Input, SimpleGrid, Box, Text, Button } from '@chakra-ui/core'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { FormError } from '../components/common'

import { AuthContext, UPDATE } from '../context/auth'
import { verify, resendCode } from '../network/auth'

const Verify = () => {
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, errors } = useForm()
  const { authState, dispatch } = useContext(AuthContext)
  const history = useHistory()

  const onSubmit = async ({ pin }) => {
    try {
      setLoading(true)
      const data = await verify({ code: pin, email: authState.user.email })
      dispatch({ type: UPDATE, payload: data.data })
      toast('Welcome to Warner Print')
      history.push('/')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast('Verifiction failed')
    }
  }

  const resendOTP = async () => {
    try {
      await resendCode(authState.user.email)
      toast('Email sent')
    } catch (error) {
      const message = error?.response?.data?.message[0]?.messages[0]?.message || 'Signup failed'
      toast(message.replace('Identifier', 'Email'))
    }
  }

  return (
    <>
      <SimpleGrid maxW="720px" mx={['3rem', 'auto']} mt={70} mb={100}>
        <Text fontSize="32px" fontFamily="black">
          Verify Email
        </Text>
        <Box mt="3rem" as="form" onSubmit={handleSubmit(onSubmit)} py={8}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={10}>
            <FormControl p={2}>
              <FormLabel htmlFor="firstname" as="" fontSize="lgx1" fontFamily="book">
                PIN <FormError>{errors.pin && errors.pin.message}</FormError>
              </FormLabel>
              <Input
                focusBorderColor="#177BE2"
                id="pin"
                placeholder="Enter OTP sent to your email"
                fontSize="15px"
                p={10}
                borderColor="#ddd"
                fontFamily="book"
                name="pin"
                ref={register({ required: 'PIN is required' })}
              />
            </FormControl>
          </SimpleGrid>
          <Box mt="1rem" ml=".5rem">
            <Button
              size="md"
              height="60px"
              width="150px"
              border="2px"
              variantColor="blue"
              fontSize="18px"
              type="submit"
              isLoading={loading}
            >
              Submit
            </Button>
          </Box>
        </Box>
        <Text ml=".5rem" py={8} fontSize="14px" fontFamily="light">
          Didn&apos;t get an email?{' '}
          <Text
            as="span"
            cursor="pointer"
            color="black"
            onClick={resendOTP}
            fontSize="14px"
            fontFamily="medium"
          >
            Resend
          </Text>
        </Text>
      </SimpleGrid>
    </>
  )
}

export { Verify }
