import React from 'react'
import { Box, Text } from '@chakra-ui/core'

function Step({ color, title }) {
  return (
    <Box position="relative">
      <Box
        mx="auto"
        my="1rem"
        w="10px"
        h="10px"
        backgroundColor={color || '#808080'}
        borderRadius="50%"
      />
      <Text
        left="50%"
        transform="translateX(-50%)"
        position="absolute"
        whiteSpace="nowrap"
        fontFamily="light"
        fontSize="1.2rem"
        color={color || '#808080'}
      >
        {title}
      </Text>
    </Box>
  )
}

export { Step }
