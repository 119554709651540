import React, { useContext, useEffect, useState, useCallback, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import _ from 'lodash'
// import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import {
  Image,
  Flex,
  Button,
  Text,
  Box,
  Heading,
  List,
  ListItem,
  Link as CLink,
  PseudoBox,
  useToast,
  Spinner,
} from '@chakra-ui/core'
import { useQuery } from 'react-query'
import { Link, useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ADD_TO_CART, CartContext } from '../context/cart'

import { PageWrapper } from '../components/layout/PageWrapper'
// import product from '../assets/images/product/1.png'
import product1 from '../assets/images/product/1.png'
import product2 from '../assets/images/product/2.png'
import product3 from '../assets/images/product/3.png'
import { Feel } from '../components/Feel'
// import jupiter from '../assets/images/jupiter-yellow.png'
import { getProduct } from '../network/product'
import express from '../assets/images/icons/express.svg'
import standard from '../assets/images/icons/standard.svg'
import upload from '../assets/images/icons/upload.svg'
import { OrderSkeleton } from '../components/skeletons'
import '../notification.css'
import { roundUp } from '../utils'
import { OrderNotification } from '../components/common'

function Order() {
  const { id } = useParams()
  const [activeQty, setActiveQty] = useState(null)
  const [activeShipping, setActiveShipping] = useState(null)
  const [choices, setChoices] = useState(null)
  const [optionsCost, setOptionsCost] = useState(0)
  const shippingBoxRef = useRef(null)
  const addToCartRef = useRef(null)
  const getQuoteRef = useRef(null)
  const [move, setMove] = useState(true)
  const [files, setFiles] = useState(null)
  const [uploadedFiles, setUploadedFiles] = useState(null)
  const [spinner, setSpinner] = useState(false)
  const history = useHistory()
  const toastify = useToast()

  // Dropzone configs
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files

    // eslint-disable-next-line no-console
    setFiles(acceptedFiles)
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  function runUpload() {
    const formData = new FormData()

    files.map((file) => formData.append('files', file))

    function completeHandler(event) {
      toast('FIle upload successful. You can now proceed.')
      setSpinner(false)
      addToCartRef.current.removeAttribute('disabled')
      getQuoteRef.current.removeAttribute('disabled')
      const response = JSON.parse(event.target.response)
      const emptyArray = []
      response.map((data) => emptyArray.push(data.url))

      setUploadedFiles(emptyArray)
      setFiles(null)
    }

    function errorHandler() {
      toast.error('An error occured, pls try again later.')
    }

    const xhr = new XMLHttpRequest()
    xhr.addEventListener('load', completeHandler, false)
    xhr.addEventListener('error', errorHandler, false)
    const baseUrl = 'https://admin.warnerprint.com/upload'
    xhr.open('POST', baseUrl)
    xhr.send(formData)
  }

  useEffect(() => {
    if (files !== null) {
      toast('Pls wait while file is being uploaded')
      addToCartRef.current.setAttribute('disabled', true)
      getQuoteRef.current.setAttribute('disabled', true)
      setSpinner(true)
      runUpload()
    }
  }, [files])

  const { dispatch } = useContext(CartContext)

  useEffect(() => {
    let sum = 0

    Object.values(choices || {}).forEach((item) => (sum += item.price))

    setOptionsCost(sum)
  }, [choices])

  useEffect(() => {
    if (activeShipping !== null) shippingBoxRef.current.classList.remove('error')
  }, [activeShipping])

  const { isLoading, data, isError } = useQuery(['order', id], getProduct)

  if (isLoading) {
    return <OrderSkeleton />
  }

  if (isError) {
    return <p>Error Occured</p>
  }

  const options = _.mapValues(_.groupBy(data.options, '__component'), (clist) =>
    clist.map((option) => _.omit(option, '__component')),
  )

  function selectQty(qtyData) {
    const updatedQty = { ...qtyData }
    const unitCost = updatedQty.price / updatedQty.amount + optionsCost
    updatedQty.unitCost = unitCost
    updatedQty.price = unitCost * updatedQty.amount
    setActiveQty(updatedQty)
  }

  function selectShipping(method) {
    setActiveShipping(method)
  }

  function selectOption(key, item) {
    const newChoices = { ...choices }
    newChoices[key] = item
    setChoices(newChoices)
  }

  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }

  function addToCart() {
    if (!isEmptyObject(options) && choices === null) {
      toast.error(`Pls Select all options`)
      return
    }

    function handleValidation(option) {
      const choice = option.split('.')[1].replace(/-/g, ' ')
      const key = option

      if (!choices[key]) {
        toast.error(`Pls select ${choice}`)
        setMove(false)
      } else setMove(true)
    }

    Object.keys(options).map(handleValidation)

    if (!move) return

    if (move && activeQty === null) {
      toast.error('Pls Select item quantity')
      return
    }

    if (move && activeShipping === null) {
      shippingBoxRef.current.classList.add('error')
      toast.error('Pls Select your type of Shipping.')
      return
    }

    if (data.require_file_upload === true || data.require_file_upload === undefined) {
      if (files === null && uploadedFiles === null) {
        toast.error('Pls upload your complete design.')
        return
      }

      if (uploadedFiles === null && files !== null) {
        toast('Pls wait for your file to finish uploading before proceeding.')
        return
      }
    }

    shippingBoxRef.current.classList.remove('error')

    window.scrollTo(0, 0)
    addToCartRef.current.setAttribute('disabled', true)
    getQuoteRef.current.setAttribute('disabled', true)

    const item = {
      cartId: Date.now(),
      id: data.id,
      name: data.name,
      quantity: activeQty,
      shipping: activeShipping,
      choices,
      optionsCost,
      price: activeQty.price + activeShipping.price + optionsCost,
      files: uploadedFiles,
      productDetails: data,
    }

    dispatch({ type: ADD_TO_CART, payload: item })

    toastify({
      position: 'bottom-left',
      render: () => <OrderNotification name={data.name} link="/checkout" />,
    })
  }

  function getQuote() {
    if (!isEmptyObject(options) && choices === null) {
      toast.error(`Pls Select all options`)
      return
    }

    function handleValidation(option) {
      const choice = option.split('.')[1].replace(/-/g, ' ')
      const key = option
      if (!choices[key]) {
        toast.error(`Pls select ${choice}`)
        setMove(false)
      } else setMove(true)
    }

    Object.keys(options).map(handleValidation)

    if (!move) return

    if (move && activeQty === null) {
      toast.error('Pls Select item quantity')
      return
    }

    if (move && activeShipping === null) {
      shippingBoxRef.current.classList.add('error')
      toast.error('Pls Select your type of Shipping.')
      return
    }

    if (data.require_file_upload === true || data.require_file_upload === undefined) {
      if (files === null && uploadedFiles === null) {
        toast.error('Pls upload your complete design.')
        return
      }

      if (uploadedFiles === null && files !== null) {
        toast('Pls wait for your file to finish uploading before proceeding.')
        return
      }
    }

    shippingBoxRef.current.classList.remove('error')

    window.scrollTo(0, 0)
    addToCartRef.current.setAttribute('disabled', true)
    getQuoteRef.current.setAttribute('disabled', true)

    const item = {
      cartId: Date.now(),
      id: data.id,
      name: data.name,
      quantity: activeQty,
      shipping: activeShipping,
      choices,
      optionsCost,
      price: activeQty.price + activeShipping.price + optionsCost,
      files: uploadedFiles,
      productDetails: data,
    }

    dispatch({ type: ADD_TO_CART, payload: item })

    history.push('/checkout/delivery')
  }

  return (
    <>
      <Box bg="#eef5fd">
        <Box maxW="1280px" mx="auto" pl={4}>
          <Flex
            p="1rem"
            ml="2rem"
            justify="flex-start"
            alignItems="center"
            h="42px"
            fontSize="1.2rem"
          >
            <Box
              background="#E3EAF1"
              border="1px solid #EDF5FD"
              pl="8px"
              pr="2rem"
              py="4.7px"
              mr="0rem"
              style={{ clipPath: 'polygon(0% 0%, 75% 0%, 83% 50%, 75% 100%, 0% 100%)' }}
            >
              <Text fontFamily="light">
                <CLink as={Link} color="#808080" to="/">
                  Home
                </CLink>
              </Text>
            </Box>
            <Box
              background="#E3EAF1"
              border="1px solid #EDF5FD"
              pl="1.5rem"
              pr="4rem"
              py="4.7px"
              style={{ clipPath: 'polygon(75% 0%, 78% 50%, 75% 100%, 0% 100%, 4% 50%, 0% 0%)' }}
              pos="relative"
              left="-1.4rem"
            >
              <Text fontFamily="light">
                <CLink as={Link} style={{ color: '#808080' }} to={`/products/${data.category.id}`}>
                  {data.category.name}
                </CLink>
              </Text>
            </Box>
            <Text pos="relative" left="-3rem" color="#808080" mr="2.5rem" fontFamily="light">
              {data.name}
            </Text>
          </Flex>
        </Box>
        <Box backgroundColor="#177BE2">
          <Box maxW="1280px" mx="auto" pl={4}>
            <Text color="white" fontSize="28px" fontFamily="black" p="30px">
              {data.name}
            </Text>
          </Box>
        </Box>
      </Box>
      <PageWrapper
        px={{ base: '4', md: '0', lg: '16' }}
        mb="10rem"
        py="2rem"
        d={{ base: 'flex', sm: 'flex' }}
        justifyContent="space-between"
      >
        <Box flex="1" mr={{ base: '0', md: '2rem' }} w="100%" maxW="900px">
          <Heading
            textAlign={{ base: 'center', md: 'left' }}
            fontFamily="heavy"
            fontSize="2rem"
            mb="2rem"
            d={{ base: 'block', md: 'none' }}
          >
            {data.name}
          </Heading>
          <Flex flexDirection={{ base: 'column', md: 'row' }}>
            <Box
              order={{ base: 2, md: 1 }}
              w={{ base: 'full', lg: '50%' }}
              pl={{ base: '1.5rem', sm: '2.7rem', lg: 0 }}
              p={{ base: '1.5rem', sm: '2.7rem', md: '2.7rem' }}
              d={{ base: 'flex', md: 'block' }}
              justifyContent="center"
              flexDirection="column"
            >
              <Heading
                textAlign={{ base: 'center', md: 'left' }}
                fontFamily="heavy"
                fontSize="2.6rem"
                d={{ base: 'none', md: 'block' }}
              >
                {data.name}
              </Heading>
              <Text
                textAlign={{ base: 'center', md: 'left' }}
                mt="0.6rem"
                fontSize={{ base: '1.3rem', md: '1.4rem' }}
                lineHeight={{ base: '1.9', md: '1.5' }}
                fontFamily="book"
              >
                {data.description}
              </Text>
              <CLink
                href="#0"
                d="inline-block"
                mt="1.8rem"
                fontFamily="medium"
                fontSize={{ base: '1.2rem', sm: '1.6rem', md: '1.8rem' }}
                px={{ base: '2.2rem', sm: '3rem', md: '4rem' }}
                py={{ base: '2rem', sm: '2rem', md: '2rem' }}
                borderRadius="0"
                variantColor="blue"
                border="none"
                background="#177BE2"
                color="#fff"
                mb="6rem"
                mx="auto"
                _hover={{
                  color: '#fff',
                  opacity: 0.8,
                }}
              >
                Start Configuration
              </CLink>
            </Box>
            <Box order={{ base: 1, md: 2 }} w={{ base: 'full', lg: '50%' }}>
              <Image w="full" maxW="100%" src={data.images[0]?.url || product1} />
              {data.images.length > 1 && (
                <Flex
                  mt="0.5rem"
                  h="100px"
                  justify="space-between"
                  d={{ base: 'none', md: 'flex' }}
                >
                  <Image w="32%" src={product1} />
                  <Image w="32%" src={product2} />
                  <Image w="32%" src={product3} />
                </Flex>
              )}
            </Box>
          </Flex>
          {/* <Box
            id="config"
            d={{ base: 'none', md: 'block' }}
            my="5rem"
            background={`url(${jupiter})`}
            backgroundSize="cover"
          >
            <Flex p="5rem" align="center" justify="space-between">
              <Box>
                <Heading fontFamily="black" fontSize="2rem">
                  1000 Polyester Face Masks now N 1,317.95
                </Heading>
                <Text mt="2" fontSize="2rem" fontFamily="light">
                  full colour, 1000
                </Text>
              </Box>
              <Button
                fontSize="1.8rem"
                py="2.7rem"
                px="5rem"
                borderRadius="0"
                fontWeight="normal"
                color="white"
                bg="black"
                border="none"
                _hover={{
                  backgroundColor: 'rgba(0,0,0, .8)',
                }}
              >
                Select this item
              </Button>
            </Flex>
          </Box> */}

          {Object.keys(options).map((key, i) => (
            <CLink
              key={key}
              href={`#${i + 1}`}
              color="#000"
              textDecoration="none"
              _hover={{ color: '#000', textDecoration: 'none' }}
            >
              <Box
                id={i}
                background={choices?.[key] ? 'rgba(23, 123, 226, .09)' : 'transparent'}
                border={choices?.[key] ? '1px solid rgba(23, 123, 226, .9)' : '1px solid #C6C6C6'}
                key={Math.random()}
                mt="3rem"
                p={{ base: '1.5rem', md: '3.5rem' }}
              >
                <Flex align="center">
                  <Flex
                    align="center"
                    justify="center"
                    size={{ base: '30px', md: '58px' }}
                    border={
                      choices?.[key] ? '1px solid rgba(23, 123, 226, .9)' : '1px solid #C6C6C6'
                    }
                    borderRadius="50%"
                  >
                    <Text
                      fontFamily="heavy"
                      fontSize={{ base: '1.4rem', sm: '2rem', md: '2.2rem' }}
                    >
                      {i + 1}
                    </Text>
                  </Flex>
                  <Heading
                    ml="2.2rem"
                    fontFamily="heavy"
                    textTransform="capitalize"
                    fontSize={{ base: '1.4rem', sm: '2rem', md: '2.2rem' }}
                  >
                    {key.split('.')[1].replace(/-/g, ' ')}
                  </Heading>
                </Flex>
                <Flex w="100%" mt="2.6rem">
                  {options[key].map((item) => (
                    <OptionBox
                      key={item.id}
                      cursor="pointer"
                      margin="1rem"
                      width={['49%', '244px']}
                      height={['200px', '270px']}
                      border="3px solid #E0E0E0"
                      shadow="0px 3px 6px #00000010"
                      className={choices?.[key]?.id === item.id ? 'active' : ''}
                      onClick={() => selectOption(key, item)}
                      _hover={{
                        border: '1px solid rgba(23, 123, 226, .9)',
                      }}
                    >
                      <Image
                        h={['130px', '201px']}
                        w="full"
                        objectFit="cover"
                        objectPosition="center"
                        src={item.image?.url}
                      />
                      <Box>
                        <Heading
                          textAlign="center"
                          fontFamily="black"
                          fontSize="1.3rem"
                          pt="2.5rem"
                        >
                          {item.name}
                        </Heading>
                      </Box>
                    </OptionBox>
                  ))}
                </Flex>
              </Box>
            </CLink>
          ))}

          <CLink
            href="#shipping"
            color="#000"
            textDecoration="none"
            _hover={{ color: '#000', textDecoration: 'none' }}
          >
            <Box
              id={Object.keys(options).length}
              background={activeQty ? 'rgba(23, 123, 226, .09)' : 'transparent'}
              mt="3rem"
              border={activeQty ? '1px solid rgba(23, 123, 226, .9)' : '1px solid #C6C6C6'}
              p={{ base: '1.5rem', md: '3.5rem' }}
            >
              <Flex align="center">
                <Flex
                  align="center"
                  justify="center"
                  size={{ base: '30px', md: '58px' }}
                  border={activeQty ? '1px solid rgba(23, 123, 226, .9)' : '1px solid #C6C6C6'}
                  borderRadius="50%"
                >
                  <Text fontFamily="heavy" fontSize={{ base: '1.4rem', sm: '2rem', md: '2.2rem' }}>
                    {Object.keys(options).length + 1}
                  </Text>
                </Flex>
                <Heading
                  ml="2.2rem"
                  fontFamily="heavy"
                  fontSize={{ base: '1.4rem', sm: '2rem', md: '2.2rem' }}
                >
                  Quantity
                </Heading>
              </Flex>
              <Box
                border={activeQty ? '1px solid rgba(23, 123, 226, .9)' : '1px solid #C6C6C6'}
                w="100%"
                mt="4rem"
                borderBottom="none"
              >
                <Flex
                  borderBottom={
                    activeQty ? '1px solid rgba(23, 123, 226, .9)' : '1px solid #C6C6C6'
                  }
                  py="2.4rem"
                  px="3.4rem"
                >
                  <Text w={['33%', '35%']} fontFamily="medium" fontSize={['1.4rem, 1.8rem']}>
                    Quantity
                  </Text>
                  <Text w={['33%', '35%']} fontFamily="medium" fontSize={['1.4rem, 1.8rem']}>
                    Unit Price
                  </Text>
                  <Text w={['33%', '20%']} fontFamily="medium" fontSize={['1.4rem, 1.8rem']}>
                    Pack Price
                  </Text>
                </Flex>
                {data.quantity.map((item) => (
                  <QtyDiv
                    key={item.id}
                    borderBottom={
                      activeQty ? '1px solid rgba(23, 123, 226, .9)' : '1px solid #C6C6C6'
                    }
                    py="2.4rem"
                    px="3.4rem"
                    cursor="pointer"
                    onClick={() => selectQty(item)}
                    className={item.id === activeQty?.id ? 'active' : ''}
                    _hover={{
                      border: '1px solid rgba(23, 123, 226, .9)',
                    }}
                  >
                    <Text w={['33%', '35%']} fontFamily="medium" fontSize="1.5rem">
                      {item.amount}
                    </Text>
                    <Text w={['33%', '35%']} fontFamily="medium" fontSize="1.5rem">
                      ₦{(item.price / item.amount + optionsCost).toFixed(2)}
                    </Text>
                    <Text w={['33%', '20%']} fontFamily="medium" fontSize="1.5rem">
                      ₦{((item.price / item.amount + optionsCost) * item.amount).toFixed(0)}
                    </Text>
                  </QtyDiv>
                ))}
              </Box>
            </Box>
          </CLink>

          <ShippingContainer ref={shippingBoxRef}>
            <Box
              id="shipping"
              mt="3rem"
              background={activeShipping ? 'rgba(23, 123, 226, .09)' : 'transparent'}
              border={activeShipping ? '1px solid rgba(23, 123, 226, .9)' : '1px solid #C6C6C6'}
              p={{ base: '1.5rem', md: '3.5rem' }}
            >
              <Flex align="center">
                <Flex
                  align="center"
                  justify="center"
                  size={{ base: '30px', md: '58px' }}
                  border={activeShipping ? '1px solid rgba(23, 123, 226, .9)' : '1px solid #C6C6C6'}
                  borderRadius="50%"
                >
                  <Text fontFamily="heavy" fontSize={{ base: '1.4rem', sm: '2rem', md: '2.2rem' }}>
                    {Object.keys(options).length + 2}
                  </Text>
                </Flex>
                <Heading
                  ml="2.2rem"
                  fontFamily="heavy"
                  fontSize={{ base: '1.4rem', sm: '2rem', md: '2.2rem' }}
                >
                  Shipping
                </Heading>
              </Flex>
              <Flex direction={['column', 'row']} w="100%" mt="2.6rem">
                {data.shipping.map((item) => (
                  <ShippingBox
                    key={item.id}
                    margin="1rem"
                    marginLeft="0"
                    width={['100%', '244px']}
                    height="81px"
                    border="3px solid #E0E0E0"
                    shadow="0px 3px 6px #00000010"
                    display="flex"
                    alignItems="center"
                    px="2.3rem"
                    cursor="pointer"
                    className={item.id === activeShipping?.id ? 'active' : ''}
                    onClick={() => selectShipping(item)}
                  >
                    <Image
                      mr="2.5rem"
                      w="38px"
                      objectFit="cover"
                      objectPosition="center"
                      src={item.name.toLowerCase() === 'standard' ? standard : express}
                    />
                    <Box>
                      <Heading textAlign="center" fontFamily="black" fontSize="1.6rem">
                        {item.name}
                      </Heading>
                    </Box>
                  </ShippingBox>
                ))}
              </Flex>
              <Box
                background={activeShipping ? '#fff' : 'rgba(23, 123, 226, .09)'}
                w="100%"
                px="3.8rem"
                py="2.2rem"
                mt="3.5rem"
              >
                <Flex>
                  <Text fontFamily="black" fontSize="1.4rem" mr="1rem">
                    Standard:
                  </Text>
                  <Text fontFamily="book" fontSize="1.4rem">
                    3 - 5 working days for Lagos, 5 - 7 working days for other cities
                  </Text>
                </Flex>
                <Flex mt="2rem">
                  <Text fontFamily="black" fontSize="1.4rem" mr="1rem">
                    Express:
                  </Text>
                  <Text fontFamily="book" fontSize="1.4rem">
                    Lagos only. Same day delivery for orders placed before 10AM. 24-hour delivery
                    for orders placed after 10AM
                  </Text>
                </Flex>
              </Box>
            </Box>
          </ShippingContainer>

          {data.require_file_upload === undefined && (
            <Box
              border="3px solid #177BE2"
              w="100%"
              maxW="900px"
              h="140px"
              mt="3rem"
              p="3.7rem"
              cursor="pointer"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <>
                  <Text fontFamily="heavy" fontSize="2.2rem">
                    Uploaded
                  </Text>
                </>
              ) : (
                <Flex>
                  <Image src={upload} maxW="65px" mr="3.7rem" />
                  <Box>
                    <Text fontFamily="heavy" fontSize="2.2rem">
                      Upload your complete design
                    </Text>
                    <Text fontFamily="book" fontSize="1.4rem" mt="1rem">
                      (AI, PDF, PSD, CDR, JPEG, PNG)
                    </Text>
                  </Box>

                  {/* {uploadedFiles !== null && (
        <Box ml="10px">
          {uploadedFiles.map((file) => (
            <Image
              src={file}
              maxW="90px"
              height="auto"
              mr="1rem"
              key={file}
              objectFit="cover"
            />
          ))}
        </Box>
      )} */}

                  <Box ml="2rem" style={{ display: spinner === true ? 'block' : 'none' }}>
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                  </Box>
                </Flex>
              )}
            </Box>
          )}
          {data.require_file_upload === true && (
            <Box
              border="3px solid #177BE2"
              w="100%"
              maxW="900px"
              h="140px"
              mt="3rem"
              p="3.7rem"
              cursor="pointer"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <>
                  <Text fontFamily="heavy" fontSize="2.2rem">
                    Uploaded
                  </Text>
                </>
              ) : (
                <Flex>
                  <Image src={upload} maxW="65px" mr="3.7rem" />
                  <Box>
                    <Text fontFamily="heavy" fontSize="2.2rem">
                      Upload your complete design
                    </Text>
                    <Text fontFamily="book" fontSize="1.4rem" mt="1rem">
                      (AI, PDF, PSD, CDR, JPEG, PNG)
                    </Text>
                  </Box>

                  {/* {uploadedFiles !== null && (
                  <Box ml="10px">
                    {uploadedFiles.map((file) => (
                      <Image
                        src={file}
                        maxW="90px"
                        height="auto"
                        mr="1rem"
                        key={file}
                        objectFit="cover"
                      />
                    ))}
                  </Box>
                )} */}

                  <Box ml="2rem" style={{ display: spinner === true ? 'block' : 'none' }}>
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                  </Box>
                </Flex>
              )}
            </Box>
          )}
          <Flex w="100%" justify="flex-end">
            <Button
              mt="1.8rem"
              fontFamily="book"
              fontSize={{ base: '1.2rem', sm: '1.6rem', md: '1.8rem' }}
              px={{ base: '2.2rem', sm: '3rem', md: '4rem' }}
              py={{ base: '2.2rem', sm: '2.3rem', md: '2.85rem' }}
              borderRadius="0"
              variantColor="blue"
              border="2px solid #177BE2"
              color="#177BE2"
              backgroundColor="#fff"
              mr="2rem"
              _hover={{
                backgroundColor: '#177BE2',
                color: '#fff',
              }}
              _focus={{
                outline: 'none',
              }}
              _active={{
                outline: 'none',
              }}
              onClick={() => getQuote('redirect')}
              ref={getQuoteRef}
            >
              Get Quote
            </Button>
            <Button
              mt="1.8rem"
              fontFamily="book"
              fontSize={{ base: '1.2rem', sm: '1.6rem', md: '1.8rem' }}
              px={{ base: '2.2rem', sm: '3rem', md: '4rem' }}
              py={{ base: '2.2rem', sm: '2.3rem', md: '3.1rem' }}
              borderRadius="0"
              variantColor="blue"
              background="#177BE2"
              border="2px solid #177BE2"
              onClick={addToCart}
              ref={addToCartRef}
            >
              Add to Cart
            </Button>
          </Flex>
        </Box>
        <Box
          d={{ base: 'none', lg: 'block' }}
          w="280px"
          h="400px"
          position="sticky"
          top="10"
          boxShadow="0px 3px 6px #00000010;"
        >
          <Heading mt="2rem" px="2rem" py="1rem" fontSize="1.7rem" fontFamily="heavy">
            Product Summary
          </Heading>
          <List>
            {/* <ListItem borderBottom="1px solid #F4F4F4" px="2rem" py="1rem">
              <Heading fontFamily="medium" fontSize="1.2rem">
                Options
              </Heading>
              <Text color="#A0A0A0" fontFamily="light" mt="2" fontSize="1.2rem">
                ₦{commafy(optionsCost)}
              </Text>
            </ListItem> */}
            <ListItem borderBottom="1px solid #F4F4F4" px="2rem" py="1rem">
              <Heading fontFamily="medium" fontSize="1.2rem">
                Quantity (Price for the selected options)
              </Heading>
              <Text color="#A0A0A0" fontFamily="light" mt="2" fontSize="1.2rem">
                {activeQty
                  ? `${activeQty.amount} for ₦${roundUp(Number(activeQty.price))}`
                  : 'No Selection'}
              </Text>
            </ListItem>
            <ListItem borderBottom="1px solid #F4F4F4" px="2rem" py="1rem">
              <Heading fontFamily="medium" fontSize="1.2rem">
                Shipping Method
              </Heading>
              <Text color="#A0A0A0" fontFamily="light" mt="2" fontSize="1.2rem">
                {activeShipping ? `₦${roundUp(Number(activeShipping.price))}` : 'No Selection'}
              </Text>
            </ListItem>{' '}
            <ListItem borderBottom="1px solid #F4F4F4" px="2rem" py="1rem">
              <Heading fontFamily="medium" fontSize="1.2rem">
                Total
              </Heading>
              <Text color="#A0A0A0" fontFamily="light" mt="2" fontSize="1.2rem">
                ₦{roundUp(Number(activeShipping?.price + activeQty?.price || 0))}
              </Text>
            </ListItem>
          </List>
        </Box>
      </PageWrapper>
      <Feel />
    </>
  )
}

export { Order }

const QtyDiv = styled(PseudoBox)`
  display: flex;

  &.active {
    border: 3px solid #177be2;
  }
`

const ShippingBox = styled(Box)`
  &.active {
    border: 3px solid #177be2;
  }

  &.error {
    border: 3px solid red;
  }
`

const ShippingContainer = styled(Flex)`
  &.error {
    border: 3px solid red;
  }
`

const OptionBox = styled(Box)`
  &.active {
    border: 3px solid #177be2;
  }
`
