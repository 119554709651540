import React from 'react'
import { FormControl, FormLabel, Input, SimpleGrid, Box, Text, Button, Link } from '@chakra-ui/core'

const ForgotPassword = () => {
  return (
    <>
      <SimpleGrid maxW="350px" mx="auto" mt={70} mb={300}>
        <Text fontSize="32px" fontFamily="black" mb={30}>
          Forgot Password
        </Text>
        <FormControl isRequired py={4}>
          <SimpleGrid>
            <Box py={6} mb={10}>
              <FormLabel htmlFor="email" fontSize="lgx1" fontFamily="book">
                Email address
              </FormLabel>
              <Input
                type="email"
                id="email"
                placeholder="Enter email address"
                fontSize="16px"
                p={10}
                borderColor="#ddd"
                fontFamily="book"
              />
            </Box>

            <Button
              size="md"
              height="60px"
              width="100%"
              border="2px"
              variantColor="blue"
              fontSize="18px"
              mt={6}
              mb={10}
            >
              Login
            </Button>
          </SimpleGrid>
        </FormControl>
        <Text py={2} fontSize="14px" fontFamily="light">
          {/* eslint-disable-next-line */}
          Dont have an account?{' '}
          <Link color="black" href="google.com" fontFamily="medium">
            {/* eslint-disable-next-line */}
            Create account{' '}
          </Link>
          now
        </Text>
      </SimpleGrid>
    </>
  )
}

export { ForgotPassword }
