import React from 'react'
import { Box, Image, Flex, Text, Heading } from '@chakra-ui/core'

import cart from '../../assets/images/icons/shopping-cart-big.svg'
import complete from '../../assets/images/icons/file.svg'
import deliver from '../../assets/images/icons/truck.png'
import pay from '../../assets/images/icons/debit-card.svg'

function Steps() {
  return (
    <>
      <Heading my="12" fontFamily="light" fontWeight="normal" fontSize="1.8rem" textAlign="center">
        Get your print items in
        <Text ml="2" as="span" fontFamily="black">
          4 simple steps!
        </Text>
      </Heading>
      <Flex
        direction={['column', 'column', 'row']}
        maxW="950px"
        mx="auto"
        justifyContent="space-between"
      >
        <Step name="Order online" icon={cart} />
        <Step name="Pay online" icon={pay} />
        <Step name="Deliver at Doorstep" icon={deliver} />
        <Step name="Order complete" icon={complete} />
      </Flex>
    </>
  )
}

function Step({ name, icon }) {
  return (
    <Flex
      direction={['row', 'row', 'column']}
      justifyContent="space-between"
      alignItems="center"
      p="3rem"
      border="1px solid #D8D8D8"
      h={['80px', '50px', '138px']}
      w={['100%', '100%', '220px']}
    >
      <Box>
        <Image w={['30px', '30px', '50px']} src={icon} />
      </Box>
      <Text fontSize={['1.6rem', '1.6rem', '1.5rem']} fontFamily="Avenir Light">
        {name}
      </Text>
    </Flex>
  )
}

export { Steps }
