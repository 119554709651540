import React from 'react'
import { SimpleGrid } from '@chakra-ui/core'

import { PageWrapper } from '../layout'
import { SectionTitle, Product } from '../common'

function Featured({ featured }) {
  return (
    <PageWrapper mt="6rem">
      <SectionTitle title={featured.title} />
      <SimpleGrid mt="12" minChildWidth="219px" spacing={10}>
        {featured.products.map((item) => (
          <Product key={item.id} item={item} />
        ))}
      </SimpleGrid>
    </PageWrapper>
  )
}

export { Featured }
