import React from 'react'
import styled from 'styled-components'

function OrderNotification({ name, link }) {
  return (
    <OrderWrapper>
      <p>
        <strong>{name}</strong> has been added to your cart
      </p>
      <a href={link}>
        <button type="button">View Cart</button>
      </a>
    </OrderWrapper>
  )
}

export { OrderNotification }

const OrderWrapper = styled.div`
  padding: 20px;
  max-width: 300px;
  margin-left: 20px;
  margin-bottom: 20px;
  background-color: #177be2 !important;
  color: #fff;
  font-size: 15px;
  font-family: 'Avenir Book';

  p strong {
    font-weight: bold !important;
  }

  button {
    display: block;
    width: 100%;
    border: solid 2px #fff;
    background: transparent;
    padding: 10px;
    margin-top: 10px;
    color: #fff;
    font-family: 'Avenir Book';
  }
`
