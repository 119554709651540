import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/core'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { Product } from '../components/common'
import { ProductsSkeleton } from '../components/skeletons'
import { getAboutPageProducts } from '../network/product'
import { Feel } from '../components/Feel'
import Aboutus from '../assets/images/about-us.PNG'

function AboutUs() {
  const { isLoading, data, isError } = useQuery(['products'], getAboutPageProducts)

  if (isLoading) {
    return <ProductsSkeleton />
  }

  if (isError) {
    return <p>Error Occured</p>
  }

  return (
    <>
      <BoxImage>
        <BoxContent
          background="rgba(0, 0, 0, 0.5)"
          color="white"
          p="3.5rem"
          lineHeight="30px"
          height="300px"
        >
          <Text
            fontFamily="'Avenir Book', sans-serif !important"
            fontSize="1.4rem"
            fontWeight="bold"
          >
            <h1>Print your ideas to Life with Warner Prints.</h1>
          </Text>
          <Text fontFamily="'Avenir Book', sans-serif !important" fontSize="1.4rem">
            we&apos;re genuine print experts and real partner to businesses big and small
          </Text>
        </BoxContent>
      </BoxImage>
      <Box p="3.2rem" mt="2rem">
        <Flex
          p="1rem"
          fontSize="1.2rem"
          fontFamily="'Avenir Book', sans-serif !important"
          wrap="wrap"
          justifyContent="center"
          m="auto"
        >
          <CustomBox
            m="auto"
            w="50%"
            textAlign="center"
            fontFamily="'Avenir Book', sans-serif !important"
          >
            <img src={Aboutus} style={{ maxWidth: '100%', width: '400px' }} alt="About us" />
          </CustomBox>
          <CustomBox m="auto" w="50%" fontFamily="'Avenir Book', sans-serif !important">
            <Text
              fontSize="23px"
              fontWeight="bold"
              fontFamily="'Avenir Book', sans-serif !important"
            >
              We Love Print
            </Text>
            <Text
              lineHeight="20px"
              mt="10px"
              style={{ fontFamily: "'Avenir Book', sans-serif", fontSize: '14px' }}
            >
              Whatever your print vision, we offer as much or little support as you need to make it
              happen. Our award-winning team of print experts is here for you and we care about
              quality as much as you do.
            </Text>
          </CustomBox>
        </Flex>
      </Box>
      <Box background="#F7F7F7" p="30px">
        <Flex
          mt="2rem"
          fontSize="2.2rem"
          fontFamily="'Avenir Book', sans-serif !important"
          wrap="wrap"
          justifyContent="center"
        >
          <CustomBox flex="0 0 25%">
            <Text fontFamily="'Avenir Book', sans-serif" fontWeight="bold" fontSize="25px">
              <b>Feel inspired</b>
            </Text>
            <Text
              mt="10px"
              fontFamily="'Avenir Book', sans-serif !important"
              fontSize="15px"
              pr="10px"
            >
              Check out the brands and designers getting creative with our products.
            </Text>
          </CustomBox>
          {data.map((item) => (
            <CustomBox flex="0 0 25%">
              <CostumProduct
                style={{ background: '#fff' }}
                key={item.id}
                item={item}
                details
                detailsLen={100}
              />
            </CustomBox>
          ))}
        </Flex>
      </Box>
      <Feel />
    </>
  )
}

export { AboutUs }

const BoxImage = styled.div`
  background: url('https://res.cloudinary.com/warner-print/image/upload/v1599491583/2_77a266df7f.png')
    no-repeat;
  background-size: cover;
  font-family: 'Avenir Book', sans-serif !important;
`

const BoxContent = styled(Box)`
  font-family: 'Avenir Book', sans-serif !important;
  font-size: 1.4rem;
`

const CustomBox = styled(Box)`
  @media (max-width: 800px) {
    flex: 50% !important;
    width: 50% !important;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 700px) {
    flex: 100% !important;
    width: 100% !important;
    margin-top: 10px;
  }
`

const CostumProduct = styled(Product)`
  @media (min-width: 480px) {
    max-width: 250px !important;
  }
`
