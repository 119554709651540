import React from 'react'
import { Box, Flex, Skeleton } from '@chakra-ui/core'

function OrderSkeleton() {
  return (
    <>
      <Skeleton h="150px" />
      <Flex
        maxW="1280px"
        mx="auto"
        d={['none', 'none', 'flex']}
        alignItems="flex-start"
        justify="space-between"
        pl="16"
      >
        <Box width="75%">
          <Flex w="100%">
            <Skeleton height="380px" width="50%" ml="0" m="10px" />
            <Skeleton height="380px" width="50%" m="10px" />
          </Flex>
          <Skeleton w="100%" h="155px" mt="2rem" />
          <Skeleton w="100%" h="900px" mt="4rem" mb="4rem" />
        </Box>
        <Skeleton height="300px" width="25%" m="10px" />
      </Flex>
    </>
  )
}

export { OrderSkeleton }
