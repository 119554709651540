/* eslint-disable react/no-children-prop */
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Box, Flex, Text, Heading, Image, Link as CLink } from '@chakra-ui/core'

import { PageWrapper } from '../components/layout'
import { Feel } from '../components/Feel'
import { Product } from '../components/common'
import { ProductsSkeleton } from '../components/skeletons'

import { getCategoryProducts } from '../network/product'

import seller from '../assets/images/seller.png'
import img14 from '../assets/images/Image 14.png'

function Category() {
  const { type } = useParams()

  const { isLoading, data, isError } = useQuery(['categoryProducts', type], getCategoryProducts)

  if (isLoading) {
    return <ProductsSkeleton />
  }

  if (isError) {
    return <p>Error Occured</p>
  }

  return (
    <>
      <Box bg="#eef5fd">
        <Box maxW="1280px" mx="auto" pl={4}>
          <Flex
            p="1rem"
            ml="2rem"
            justify="flex-start"
            alignItems="center"
            h="42px"
            fontSize="1.2rem"
          >
            <Box
              background="#E3EAF1"
              border="1px solid #EDF5FD"
              pl="8px"
              pr="10px"
              py="4.7px"
              mr="2rem"
              style={{ clipPath: 'polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)' }}
            >
              <Text fontFamily="light">
                <CLink color="#808080" as={Link} to="/">
                  Home
                </CLink>
              </Text>
            </Box>
            <Text color="#808080" mr="2.5rem" fontFamily="light">
              {data[0]?.category.name}
            </Text>
          </Flex>
        </Box>
        <Box backgroundColor="#177BE2">
          <Box maxW="1280px" mx="auto" pl={4}>
            <Text color="white" fontSize="28px" fontFamily="black" p="30px">
              {data[0]?.category.name}
            </Text>
          </Box>
        </Box>
      </Box>

      <PageWrapper mt="6rem">
        <Flex justify="space-between">
          <Image
            d={['none', 'none', 'none', 'flex']}
            mr="1.7rem"
            w="220px"
            flexShrink="0"
            src={seller}
            maxH="450px"
          />
          <Box w="100%" display="flex" flexDir="column">
            <Flex wrap="wrap" mt="2.5rem">
              {data.map((item) => (
                <Product key={item.id} item={item} details detailsLen={100} />
              ))}
            </Flex>
          </Box>
        </Flex>
      </PageWrapper>

      <PageWrapper mt="10rem" mb="5.5rem">
        <Heading fontWeight="normal" fontSize="3.2rem " fontFamily="medium">
          Other Categories
        </Heading>
        <Flex justify="space-between" overflow="hidden">
          {/* <SimpleGrid mt="12" w="100%" minChildWidth="219px" columns={4} spacing={10}>
            <Product />
            <Product />
            <Product />
            <Product />
            <Product />
            <Product />
            <Product />
            <Product />
          </SimpleGrid> */}
          <Image d={['none', 'none', 'none', 'flex']} ml="6" w="220px" src={img14} />
        </Flex>
      </PageWrapper>

      <Feel />
    </>
  )
}

export { Category }
