import React from 'react'
import { Flex, Image } from '@chakra-ui/core'

function Images({ images }) {
  return (
    <Flex flexWrap="wrap">
      {images.map((item) => (
        <Image key={item.id} w={['50%', '50%', '25%']} src={item.url} />
      ))}
    </Flex>
  )
}

export { Images }
