import React from 'react'
import { Flex, Heading } from '@chakra-ui/core'

function SectionTitle({ title }) {
  return (
    <Flex justify="space-between" align="center">
      <Heading fontWeight="normal" fontSize={['2.5rem', '2.5rem', '3.2rem']} fontFamily="medium">
        {title}
      </Heading>
      {/* <Text d={['none', 'none', 'block']} fontSize="2rem" color="brand.blue" fontFamily="light">
        Sell all →
      </Text> */}
    </Flex>
  )
}

export { SectionTitle }
