import React, { createContext, useReducer, useEffect } from 'react'
import { authReducer } from './reducer'

const AuthContext = createContext()

const AuthContextProvider = ({ children }) => {
  let initialState = localStorage.getItem('auth')

  if (initialState) {
    initialState = JSON.parse(initialState)
  } else {
    initialState = {}
  }

  const [authState, dispatch] = useReducer(authReducer, initialState)

  useEffect(() => {
    localStorage.setItem('auth', JSON.stringify(authState))
  }, [authState])

  return <AuthContext.Provider value={{ authState, dispatch }}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthContextProvider }
